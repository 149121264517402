import request from '@/utils/request'

// 查询流程列表
export function listResource(query) {
  return request({
    url: '/bpm/resource/list',
    method: 'get',
    params: query
  })
}

// 查询流程详细
export function getResource(id, init="0",resource="") {
  return request({
    url: '/bpm/resource/get',
    method: 'post',
    data: {
      id: id,
      init:init,
      resource:resource
    }
  })
}

export function getResourceStartData(id,flowId) {
  return request({
    url: '/bpm/resource/data/start?id=' + id+"&flow_id="+flowId,
    method: 'get'
  })
}

export function getResourceStepData(resourceId,processId,vars) {
  return request({
    url: '/bpm/resource/data/step',
    method: 'post',
    data: {
      resourceId:resourceId,
      processId:processId,
      vars:vars
    }
  })
}

export function getResourceTaskData(resourceId,taskId,processId) {
  return request({
    url: '/bpm/resource/data/task?taskId=' + taskId + "&processId="+processId + "&resourceId="+resourceId,
    method: 'get'
  })
}


// 删除流程资源
export function delResource(id) {
  return request({
    url: '/bpm/resource/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}
