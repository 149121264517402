<template>
    <div class="app-container">
        <div class="flow-action-btn">
            <!--<el-button  v-noMoreClick size="mini"  :loading="loading" icon="el-icon-printer">打印</el-button>-->
            <template v-for="(button, index) in buttons" v-if="false">
                <el-button
                    :type="button.type"
                    size="mini"
                    v-noMoreClick
                    v-if="button.show"
                    :loading="loading"
                    :icon="button.icon"
                    :key="index"
                    @click="doSubmit(button)"
                >
                    {{ button.name }}</el-button
                >
            </template>
        </div>
        <el-tabs v-model="activeStep" class="task-tab" v-if="showComp">
            <el-tab-pane label="表单信息" name="form">
                <span slot="label"> <i class="fa fa-tasks"></i> 表单信息 </span>
                <el-card class="form-card">
                    <div slot="header" class="clearfix">
                        <span><i class="fa fa-edit"></i> 表单信息</span>
                        <el-button
                            v-if="startfrom == ''"
                            style="float: right; padding: 3px 0"
                            size="mini"
                            :icon="
                                expandedLink
                                    ? 'el-icon-d-arrow-right'
                                    : 'el-icon-d-arrow-left'
                            "
                            type="text"
                            @click="expandedLink = !expandedLink"
                            >{{ expandedLink ? "折叠" : "展开" }}</el-button
                        >
                    </div>
                    <el-row>
                        <el-col
                            :span="expandedLink ? 16 : 24"
                            :class="expandedLink ? 'expanded-col' : ''"
                        >
                            <template v-if="showComp">
                                <generate-form
                                    ref="form"
                                    :conf="resourceData.formData"
                                    v-if="resourceData && resourceData.formData"
                                    @formMounted="onFormMounted"
                                    @inputChange="onInputChange"
                                    @conditionChange="onConditionChange"
                                />
                            </template>
                        </el-col>
                        <el-col :span="8" v-show="expandedLink">
                            <bpm-process-link
                                ref="links"
                                v-if="flowInstId > 0"
                                :steps-data="stepsData"
                                :title="title"
                                :process-id="flowInstId"
                                :flow-name="flowName"
                                :process-no="processNo"
                                :task-data="taskData"
                                @doPrint="doSumitCode('print')"
                            ></bpm-process-link>
                            <bpm-steps
                                ref="steps"
                                :data="stepsData"
                                v-else
                            ></bpm-steps>
                        </el-col>
                    </el-row>
                </el-card>
                <!--(!flowInstId || taskId>0)-->
                <el-card class="task-card" v-if="taskId > 0">
                    <div slot="header" class="clearfix">
                        <span><i class="fa fa-file-text-o"></i> 流程处理</span>
                    </div>
                    <el-form
                        size="small"
                        :model="taskForm"
                        ref="taskForm"
                        label-width="150px"
                    >
                        <el-col :span="18" v-if="!flowInstId && false">
                            <el-form-item label="流程名称" prop="title">
                                <el-input
                                    placeholder="请输入流程名称"
                                    v-model="title"
                                    readonly
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="18" v-if="taskId > 0">
                            <el-form-item
                                label="处理意见"
                                prop="comment"
                                :rules="[
                                    {
                                        required: true,
                                        message: '处理意见不能为空',
                                        trigger: 'blur',
                                    },
                                ]"
                            >
                                <div class="opinion-role" v-if="false">
                                    <div class="action">
                                        <el-button
                                            size="mini"
                                            :loading="loading"
                                            icon="el-icon-edit"
                                            plain
                                            type="primary"
                                            >我的审批语</el-button
                                        >
                                    </div>
                                </div>
                                <el-select
                                    v-model="opinion"
                                    filterable
                                    placeholder="请选择常用语"
                                    @change="handleOpinionChange"
                                >
                                    <el-option
                                        v-for="item in opinions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.label"
                                    >
                                    </el-option>
                                </el-select>
                                <el-input
                                    type="textarea"
                                    :rows="3"
                                    placeholder="请输入处理意见"
                                    v-model="taskForm.comment"
                                    resize="none"
                                    maxlength="1000"
                                    show-word-limit
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <template>
                            <el-col :span="18" v-if="false">
                                <el-form-item>
                                    <el-checkbox v-model="isCandidate"
                                        >指定下一步操作人(不设置则由流程自动分配)</el-checkbox
                                    >
                                    <el-checkbox v-model="isCopy"
                                        >是否抄送</el-checkbox
                                    >
                                </el-form-item>
                            </el-col>
                            <el-col :span="18" v-if="isCandidate">
                                <el-form-item prop="candidates" label="审批人">
                                    <admin-select
                                        size="small"
                                        placeholder="请选择用户"
                                        title="用户"
                                        :value="taskForm.candidates"
                                        :ignoreDatascope="1"
                                        readonly
                                        @getValue="
                                            (value) => {
                                                taskForm.candidates = value;
                                            }
                                        "
                                    ></admin-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="18" v-if="isCopy">
                                <el-form-item prop="users" label="抄送人">
                                    <admin-select
                                        size="small"
                                        placeholder="请选择用户"
                                        title="用户"
                                        :value="taskForm.ccs"
                                        :ignoreDatascope="1"
                                        readonly
                                        @getValue="
                                            (value) => {
                                                taskForm.ccs = value;
                                            }
                                        "
                                    ></admin-select>
                                </el-form-item>
                            </el-col>
                        </template>
                    </el-form>
                </el-card>
            </el-tab-pane>
            <el-tab-pane label="执行流" name="branch" v-if="flowInstId > 0">
                <span slot="label">
                    <i class="fa fa-share-alt"></i> 执行流
                </span>
                <el-card class="history-card">
                    <div slot="header" class="clearfix">
                        <span><i class="fa fa-share-alt"></i> 执行流</span>
                    </div>
                    <el-steps
                        direction="vertical"
                        :active="taskData.step + 1"
                        :space="100"
                    >
                        <template v-for="(node, index) in nodeList">
                            <el-step
                                :title="node.title"
                                :description="node.content ? node.content : ''"
                            ></el-step>
                        </template>
                    </el-steps>
                </el-card>
            </el-tab-pane>
            <el-tab-pane label="流转信息" name="step" v-if="flowInstId > 0">
                <span slot="label">
                    <i class="fa fa-history"></i> 流转信息
                </span>
                <el-card class="history-card">
                    <div slot="header" class="clearfix">
                        <span><i class="fa fa-history"></i> 流转信息</span>
                    </div>
                    <el-steps :active="taskList.length">
                        <template v-for="(step, index) in taskList">
                            <el-step
                                :title="step.node_name"
                                :description="`${
                                    step.assignee_name
                                },${parseTime(step.start_time)}`"
                            ></el-step>
                        </template>
                    </el-steps>
                    <br />
                    <el-table :data="taskList" border style="width: 100%">
                        <el-table-column
                            type="index"
                            label="#"
                        ></el-table-column>
                        <el-table-column
                            prop="node_name"
                            label="名称"
                        ></el-table-column>
                        <el-table-column
                            prop="assignee_name"
                            label="处理人"
                        ></el-table-column>
                        <el-table-column
                            prop="assignee_view"
                            label="处理意见"
                        ></el-table-column>
                        <el-table-column prop="start_time" label="开始时间">
                            <template slot-scope="scope">
                                <span>{{
                                    parseTime(scope.row.start_time)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="end_time" label="结束时间">
                            <template slot-scope="scope">
                                <span>{{ parseTime(scope.row.end_time) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="duration" label="任务历时">
                            <template slot-scope="scope">
                                <span>{{
                                    formatDuration(scope.row.duration)
                                }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-tab-pane>
            <el-tab-pane label="流程图" name="four">
                <span slot="label">
                    <i class="fa fa-file-picture-o"></i> 流程图
                </span>
                <el-card class="chart-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span><i class="fa fa-file-picture-o"></i> 流程图</span>
                    </div>
                    <div class="flow-container">
                        <FlowCard
                            v-if="showComp"
                            :data="resourceData.processData"
                        />
                    </div>
                </el-card>
            </el-tab-pane>
            <el-tab-pane name="processTitle" disabled>
                <template slot="label">
                    <div
                        class="flow-title"
                        style="color: #606266; font-weight: bold"
                    >
                        <i class="fa fa-info-circle"></i> {{ title }}
                    </div>
                </template>
            </el-tab-pane>
        </el-tabs>

        <div
            :class="
                startfrom == '' ? 'task-footer' : 'task-footer customfooter'
            "
        >
            <template v-for="(button, index) in buttons">
                <el-button
                    :type="button.type"
                    size="small"
                    v-if="button.show"
                    v-noMoreClick
                    :loading="loading"
                    :icon="button.icon"
                    :key="index"
                    @click="doSubmit(button)"
                >
                    {{ button.name }}</el-button
                >
            </template>
            <!--<el-button  v-noMoreClick size="small"  :loading="loading" icon="el-icon-printer">打印</el-button>-->
        </div>
    </div>
</template>
<script>
import {
    getResourceStartData,
    getResourceTaskData,
    getResourceStepData,
} from "@/api/bpm/resource";
import throttle from "@/utils/throttle";
import { startProcess, PrintProcess } from "@/api/bpm/process";
import { completeTask, getProcessTaskList, rejectTask } from "@/api/bpm/task";
import GenerateForm from "@/components/jpgflow/DynamicForm/GenerateForm";
import FlowCard from "@/components/jpgflow/Process/FlowCard/index.vue";
import AdminSelect from "@/components/AdminSelect";
import BpmProcessLink from "../components/ProcessLink";
import BpmSteps from "../components/BpmSteps";
import * as Formx from "@/api/form/data";

export default {
    name: "bpm-task-task",
    props: {
        propsflowid: {
            type: String,
            default: () => {
                return null;
            },
        },
        startfrom: {
            type: String,
            default: () => {
                return "";
            },
        },
    },
    components: {
        GenerateForm,
        FlowCard,
        AdminSelect,
        BpmProcessLink,
        BpmSteps,
    },
    watch: {
        "taskForm.comment": {
            handler(newVal) {
                this.opinionFormFields.forEach((field) => {
                    this.$refs.form.setData(newVal, field);
                });
            },
        },
    },
    data() {
        return {
            resourceData: {}, // 可选择诸如 $route.param，Ajax获取数据等方式自行注入
            taskFormData: {},
            activeStep: "form", // 激活的步骤面板
            showComp: false,
            loading: false,
            title: "",
            processNo: "",
            expandedLink: true,
            flowName: "",
            flowId: this.$route.query.flowId || this.propsflowid,
            flowInstId: this.$route.query.id || 0,
            taskId: this.$route.query.taskId || 0,
            isCopy: false,
            isCandidate: false,
            taskData: {
                is_candicate: true,
                step: 0,
            },
            useReadWrite: true,
            opinion: "",
            taskForm: {
                code: "",
                comment: "",
                candidates: null,
                ccs: null,
            },
            resourceId: this.$route.query.resourceId || 0,
            time: this.$route.query.time || this.XEUtils.now(),
            buttons: [
                {
                    code: "save",
                    name: "保存",
                    type: "",
                    icon: "fa fa-save",
                    show: false,
                },
                {
                    code: "submit",
                    name: "同意",
                    type: "primary",
                    icon: "fa fa-check",
                    show: true,
                },
                {
                    code: "back",
                    name: "回退",
                    type: "",
                    icon: "fa fa-arrow-left",
                    show: false,
                },
                {
                    code: "reject",
                    name: "拒绝",
                    type: "danger",
                    icon: "fa fa-mail-reply",
                    show: true,
                },
                {
                    code: "transfer",
                    name: "转办",
                    type: "warning",
                    icon: "fa fa-mail-forward",
                    show: false,
                },
                {
                    code: "delegate",
                    name: "委派",
                    type: "success",
                    icon: "fa fa-retweet",
                    show: false,
                },
                {
                    code: "stop",
                    name: "终止",
                    type: "danger",
                    icon: "fa fa-power-off",
                    show: false,
                },
                {
                    code: "sign",
                    name: "签收",
                    type: "warning",
                    icon: "fa fa-sign-in",
                    show: false,
                },
                {
                    code: "print",
                    name: "打印",
                    type: "",
                    icon: "fa fa-print",
                    show: false,
                },
            ],
            opinions: [
                { value: "1", label: "同意" },
                { value: "3", label: "不同意" },
                { value: "4", label: "材料不齐全" },
            ],
            opinionFormFields: [],
            taskList: [],
            nodeList: [],
            stepsData: [],
        };
    },
    created() {
        this.init();
        if (this.startfrom != "") {
            this.expandedLink = false;
        }
    },
    methods: {
        init() {
            this.showComp = false;
            this.$nextTick(() => {
                if (this.taskId > 0) {
                    //读取任务数据 resourceId,taskId,processId
                    getResourceTaskData(
                        this.resourceId,
                        this.taskId,
                        this.flowInstId
                    )
                        .then((response) => {
                            let data = response.data;
                            if (data) {
                                this.taskData = data;
                                this.nodeList = JSON.parse(
                                    data.processExecution.node_infos
                                );
                                let resourceData = JSON.parse(data.resource);
                                this.activeStep = "form"; // 激活的步骤面板
                                this.processNo = data.processNo;
                                this.flowName = data.flow_name;
                                this.showComp = true;
                                this.flowId = data.flow_id;
                                this.resourceId = data.resourceId;
                                this.title = data.title;
                                this.taskFormData = data.vars;
                                if (data.step == 0) {
                                    this.buttons = [
                                        /*{code: 'save', name: '保存', type: '', icon:'fa fa-save', show: true},*/
                                        {
                                            code: "submit",
                                            name: "提交",
                                            type: "primary",
                                            icon: "fa fa-check",
                                            show: true,
                                        },
                                    ];
                                }
                                this.resourceData = this.initResourceData(
                                    resourceData,
                                    this.getFormOperates(
                                        data.node_id,
                                        this.nodeList
                                    )
                                );
                            }
                        })
                        .catch((e) => {
                            this.handleError(null, "/bpm/process/todoList");
                            return;
                        });

                    // 读取任务列表
                    getProcessTaskList(this.flowInstId).then((response) => {
                        this.taskList = response.data.list;
                    });
                    //读取执行流信息
                } else {
                    if (this.flowId <= 0 && this.resourceId <= 0) {
                        return;
                    }
                    this.buttons = [
                        /*{code: 'save', name: '保存', type: '', icon:'fa fa-save', show: true},*/
                        {
                            code: "submit",
                            name: "提交",
                            type: "primary",
                            icon: "fa fa-check",
                            show: true,
                        },
                    ];
                    //发起流程
                    //读取定义
                    getResourceStartData(this.resourceId, this.flowId)
                        .then((response) => {
                            let data = response.data;
                            if (data) {
                                let resourceData = JSON.parse(data.resource);
                                this.activeStep = "form"; // 激活的步骤面板
                                this.showComp = true;
                                this.flowId = data.flow_id;
                                this.flowName = data.flow_name;
                                this.resourceId = data.resourceId;
                                //this.title = `${this.$store.state.user.name} 在 ${this.XEUtils.toDateString(this.time)} 提交了 [${data.flow_name}]`
                                console.log("this.$store", this.$store);
                                this.title = `${this.$store.state.user.username}提交的${data.flow_name}`;

                                this.resourceData = this.initResourceData(
                                    resourceData,
                                    resourceData.processData.properties
                                        .formOperates
                                );
                            }
                        })
                        .catch((e) => {
                            this.handleError(null); //错误
                            return;
                        });
                }
            });
        },
        async getFormsrvData(mid) {
            var param=  {
        fieldval: {},
        sortinfo: null,
        pageNum: 1,
        pageSize: 100,
      };
            let res =await Formx.FormxShowList(mid, param);
           // console.log("getFormsrvData res", res.data.list);
            return res.data.list;
        },

        initResourceData(resourceData, formOperates) {
            console.log("formOperates", formOperates);
            let operMap = new Map();
            if (formOperates) {
                formOperates.forEach((item) => {
                    operMap[item.formId] = item.formOperate;
                });
            }
            resourceData.formData.fields.forEach((field) => {
                this.setFormField(operMap, field);
                if (this.useReadWrite) {
                    field.asOpinion =
                        field.asOpinion || field.label.indexOf("意见") != -1
                            ? true
                            : false;
                    if (
                        this.flowInstId &&
                        ["input", "textarea"].includes(field.tagIcon) &&
                        field.asOpinion &&
                        !field.disabled &&
                        this.taskData.is_candicate
                    ) {
                        this.opinionFormFields.push(field.vModel);
                    }
                }
                Array.isArray(field.children) &&
                    field.children.forEach((childField) => {
                        this.setFormField(operMap, childField);
                    });
            });
            return resourceData;
        },
        setFormField(operMap, field) {
            if(field.formsvr && field.formsvr.id){
               var formdatalist = this.getFormsrvData(field.formsvr.id)
               formdatalist.then(rs=>{
                let toption=[]
               for(let ii in rs){
                let onerec = rs[ii]
                toption.push({label:onerec[field.formsvr.optionLabel],value:onerec[field.formsvr.optionValue],originData:onerec})

               }
               //console.log("toption",toption,rs)

               field.options=toption
               })
               
            }
      

            if (!this.useReadWrite) {
                //不可写
                field.defaultValue = this.taskFormData[field.vModel];
                if (this.flowInstId) {
                    field.disabled = true;
                    field.required = false;
                }
                return;
            }
            //操作 2可写 0隐藏 1只读
            if (this.flowInstId) {
                field.defaultValue = this.taskFormData[field.vModel];

                if (
                    this.taskData.processFinished === "1" ||
                    !this.taskData.is_candicate
                ) {
                    //流程已结束或非候选人只读
                    console.log("!is_candicate");
                    field.disabled = true;
                    field.required = false;
                    return;
                }
            }
            if (!operMap.hasOwnProperty(field.formId)) {
                console.log("!operMap.hasOwnProperty");
                field.disabled = false;
                return;
            }
            let oper = operMap[field.formId];
            field.disabled = false;
            switch (oper) {
                case 0: // 隐藏
                    field.disabled = true;
                    field.required = false;
                    break;
                case 1: // 只读
                    field.disabled = true;
                    field.required = false;
                    break;
                case 2: //可写
                    field.disabled = false;
                    break;
            }
            console.log("operMap field", operMap, field);
        },

        getFormOperates(nodeId, nodeList) {
            if (!nodeList) {
                return null;
            }
            for (let i = 0; i < nodeList.length; i++) {
                let item = nodeList[i];
                if (item.nodeId == nodeId && item.properties) {
                    return item.properties.formOperates;
                }
            }
            return null;
        },
        onInputChange(field, val) {
            this.opinionFormFields.forEach((item) => {
                if (item == field) {
                    this.taskForm.comment = val;
                }
            });
        },
        onFormMounted() {
            let vars = this.taskFormData;
            for (let key in vars) {
                this.$refs.form.setData(vars[key], key);
            }

            //表单渲染完成
            this.onConditionChange();
        },
        onConditionChange() {
            if (!this.$refs.form) {
                return;
            }
            //防止多次调用
            throttle(() => {
                let that = this;
                let vars = JSON.stringify(this.$refs.form.getDataFields());
                getResourceStepData(
                    this.resourceId,
                    this.flowInstId,
                    vars
                ).then((response) => {
                    let steps = JSON.parse(
                        decodeURIComponent(response.data.steps)
                    );
                    that.stepsData = steps;
                });
            }, 500);
        },
        handleError(msg, path) {
            path = path || "/bpm/task/flowList";
            if (msg) {
                this.msgError(msg);
            }
            this.$store.dispatch("tagsView/delView", this.$route);
            this.$router.push({ path: path });
        },
        handleOpinionChange(value) {
            if (value != "") {
                this.taskForm.comment = value;
            }
        },
        save() {
            if (this.taskId <= 0) {
                //创建实例和保存数据
                this.start();
                return;
            }
            // TODO 保存任务数据
        },
        submit() {
            if (this.taskId <= 0) {
                //创建和提交实例
                this.start();
                return;
            }
            this.$refs["taskForm"].validate((valid) => {
                if (valid) {
                    //提交任务
                    this.complete(); //
                } else {
                    this.msgError("请输入处理意见");
                    return false;
                }
            });
        },
        start() {
            let that = this;
            this.$refs.form
                .getData()
                .then((data) => {
                    let stepUsers =
                        that.$refs.steps && that.$refs.steps.getStepUser();
                    if (!stepUsers) {
                        return;
                    }
                    startProcess({
                        flowId: this.flowId,
                        title: this.title,
                        time: this.time,
                        resourceId: this.resourceId,
                        code: this.taskForm.code,
                        users: JSON.stringify(stepUsers),
                        vars: JSON.stringify(data),
                    }).then((response) => {
                        //let ret = response.data
                        this.msgSuccess("发起成功");
                        // console.log("this.startfrom",this.startfrom)
                        if (this.startfrom == "") {
                            this.$store.dispatch(
                                "tagsView/delView",
                                this.$route
                            );
                            this.$router.push({
                                path: "/bpm/process/todoList",
                            });
                        } else {
                            this.$emit("startsuccess");
                        }
                    });
                })
                .catch((e) => {});
        },
        complete() {
            this.$refs.form
                .getData()
                .then((data) => {
                    //完成任务提交
                    completeTask(this.taskForm.code, {
                        ...this.taskForm,
                        resourceId: this.resourceId,
                        processId: this.flowInstId,
                        taskId: this.taskId,
                        vars: JSON.stringify(data),
                    }).then((response) => {
                        //let ret = response.data
                        this.msgSuccess("提交成功");
                        console.log("this.startfrom", this.startfrom);
                        if (this.startfrom == "") {
                            this.$store.dispatch(
                                "tagsView/delView",
                                this.$route
                            );
                            this.$router.push({
                                path: "/bpm/process/todoList",
                            });
                        } else {
                            this.$router.push({ path: this.startfrom });
                        }
                    });
                })
                .catch((e) => {});
        },
        back() {
            // 回退
            this.$refs.form
                .getData()
                .then((data) => {
                    //回退任务提交
                    completeTask(this.taskForm.code, {
                        ...this.taskForm,
                        resourceId: this.resourceId,
                        processId: this.flowInstId,
                        taskId: this.taskId,
                        vars: JSON.stringify(data),
                    }).then((response) => {
                        //let ret = response.data
                        this.msgSuccess("回退成功");
                        this.$store.dispatch("tagsView/delView", this.$route);
                        this.$router.push({ path: "/bpm/process/todoList" });
                    });
                })
                .catch((e) => {});
        },
        reject() {
            //驳回
            this.$refs.form
                .getData()
                .then((data) => {
                    //驳回任务提交
                    rejectTask({
                        ...this.taskForm,
                        resourceId: this.resourceId,
                        processId: this.flowInstId,
                        taskId: this.taskId,
                        code: this.taskForm.code,
                        vars: JSON.stringify(data),
                    }).then((response) => {
                        //let ret = response.data
                        this.msgSuccess("驳回成功");
                        this.$store.dispatch("tagsView/delView", this.$route);
                        this.$router.push({ path: "/bpm/process/todoList" });
                    });
                })
                .catch((e) => {});
        },
        transfer() {
            //转办
        },
        delegate() {
            //委派
        },
        stop() {
            //终止
        },
        sign() {
            //签收
        },
        print() {
            //打印
            PrintProcess(this.flowInstId, this.processNo);
        },
        doSumitCode(code) {
            this.doSubmit({
                code: code,
            });
        },
        doSubmit(button) {
            this.activeStep = "form";
            this.taskForm.code = button.code;
            switch (button.code) {
                case "save": // 保存草稿
                    this.save();
                    break;
                case "submit": // 提交
                    this.submit();
                    break;
                case "back": //回退
                    this.back();
                    break;
                case "reject": //驳回
                    this.reject();
                    break;
                case "transfer": //转办
                    this.transfer();
                    break;
                case "delegate": //委派
                    this.delegate();
                    break;
                case "stop": //终止
                    this.stop();
                    break;
                case "sign": //签收
                    this.sign();
                    break;
                case "print": // 打印
                    this.print();
                    break;
                default:
                    this.complete(); //提交任务
            }
        },
    },
};
</script>
<style lang="scss" scoped >
.task-card {
    margin-top: 15px;
    margin-bottom: 60px;
}
.card-bottom,
.history-card {
    margin-bottom: 60px;
}
.opinion-role {
    position: relative;
    .action {
        position: absolute;
        top: -1px;
        left: 210px;
    }
}
::deep .el-input-number .el-input__inner {
    text-align: left;
}
.task-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 200px;
    height: 60px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background: #fff;
    box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.12);
    transition: inline-block 0.3s, left 0.3s, width 0.3s, margin-left 0.3s,
        font-size 0.3s;
    -webkit-transition: inline-block 0.3s, left 0.3s, width 0.3s,
        margin-left 0.3s, font-size 0.3s;
}
::deep .el-button [class*="fa-"] + span {
    margin-left: 5px;
}
.customfooter {
    left: 0 !important;
}
.hideSidebar .task-footer {
    left: 54px;
}
.task-tab {
    margin-bottom: 70px;
}
.flow-action-btn {
    right: 20px;
    position: absolute;
    top: 25px;
    z-index: 500;
}
.chart-card {
    margin-bottom: 60px;
    .el-card__body {
        /*padding: 0;*/
        /*background: #f5f5f7;*/
    }
    .flow-container {
        display: inline-block;
        padding: 20px 0;
        background: #f5f5f7;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        text-align: center;
        overflow: auto;
    }
}
.expanded-col {
    margin-right: -1px;
    border-right: 1px solid #e6ebf5;
}
</style>
