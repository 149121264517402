<template>
  <div>
    <el-date-picker v-model="timeRange" v-bind="{...$props, ...$attrs}" v-on="$listeners" :picker-options="pickerOptions" ></el-date-picker>
    <div v-if="showDuration" class="explain-text">{{ durationText }}: {{Number.isNaN(duration)?'':duration}}</div>
  </div>
</template>
<script>
import { timeCalculate } from '@/utils'
export default {
  model:{
    prop: 'value',
    event: 'change'
  },
  props:["value","cmpType", "showDuration","tag", "dateRange","tagIcon", "defaultValue", "labelWidth", "range-separator", "start-placeholder", "end-placeholder", "clearable", "format", "value-format", "regList", "changeTag", "proCondition", "asSummary", "formId", "renderKey", "layout", "value"],
  name: 'fc-date-duration',
  created() {
  },
  data(){
    return {
      timeRange: this.value || this.defaultValue || ['', ''],
      duration: 0,
      pickerOptions:{
        disabledDate:this.disabledDate
      }
    }
  },
  computed:{
    durationText() {
      if(this.format && this.format.indexOf("HH:mm") != -1) {
        return '时长（小时）'
      }
      return  '时长（天）'
    }
  },
  methods:{
    disabledDate(time) {
      let dateRange = this.dateRange
      if(!dateRange || dateRange == '') {
        return false
      }
      if(dateRange == '0' || dateRange == '0-0') {
        //只能选今天
        const start = new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1);
        return time.getTime() < new Date().getTime() - 1 * 24 * 60 * 60 * 1000 || time.getTime() > new Date().getTime() + (start - new Date().getTime());
      }
      let range = dateRange.split('-',2)
      if (range.length == 0) {
        return false
      }
      let start = parseInt(range[0])
      let end = parseInt('')
      if (range.length > 1) {
        end = parseInt(range[1])
      }
      if(Number.isNaN(start) && Number.isNaN(end)) {
        return false
      }
      if(Number.isNaN(start)) {
        //m天之前
        return time.getTime() > (Date.now() + end * 24 * 60 * 60 * 1000)
      }
      if(Number.isNaN(end)) {
        //n天之后
        return time.getTime() < Date.now()- (start+1) * 24 * 60 * 60 * 1000
      }
      return  time.getTime() > (Date.now() + end * 24 * 60 * 60 * 1000) || time.getTime() < Date.now()- (start+1) * 24 * 60 * 60 * 1000
    },
    timeCalculate(start, end) {
      let duration = 0
      if(this.format && this.format.indexOf("HH:mm") != -1) {
        //return '时长（小时）'
        duration = (( end - start ) / 3600000).toFixed(2)
      }else {
        //return '时长（天）'
        duration =  (( end - start ) / 86400000 ).toFixed(2)
      }

      return  parseFloat(duration)
    }
  },
  watch:{
    'timeRange':{
      handler:  function(val){
        let res = 0
        if (Array.isArray(this.timeRange)) {
          const [start, end] = this.timeRange.slice(0, 2).map( c => new Date(c).getTime())
          res = this.timeCalculate(start, end)
        }
        this.duration = res
        this.$emit('change', val)
      },
      immediate: true
    },
    value (val) {
      if (!val) {
        this.timeRange = ['', '']
      } else if (Array.isArray(val) && this.timeRange + '' !== val + '') {
        this.timeRange = val
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.explain-text {
  font-size: 12px;
  color: #909399;
  line-height: 20px;
}
</style>
