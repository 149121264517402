const makeMap = ( attrStr, expectsLowerCase ) => {
  const attrs = attrStr.split( ',' )
  const map = Object.create( null )
  attrs.forEach( t => map[t] = true )
  return expectsLowerCase ?
    val => map[val.toLowerCase()] :
    val => map[val]
}

// 参考https://github.com/vuejs/vue/blob/v2.6.10/src/platforms/web/server/util.js
const isAttr = makeMap(
  'accept,accept-charset,accesskey,action,align,alt,async,autocomplete,'
  + 'autofocus,autoplay,autosave,bgcolor,border,buffered,challenge,charset,'
  + 'checked,cite,class,code,codebase,color,cols,colspan,content,http-equiv,'
  + 'name,contenteditable,contextmenu,controls,coords,data,datetime,default,'
  + 'defer,dir,dirname,disabled,download,draggable,dropzone,enctype,method,for,'
  + 'form,formaction,headers,height,hidden,high,href,hreflang,http-equiv,'
  + 'icon,id,ismap,itemprop,keytype,kind,label,lang,language,list,loop,low,'
  + 'manifest,max,maxlength,media,method,GET,POST,min,multiple,email,file,'
  + 'muted,name,novalidate,open,optimum,pattern,ping,placeholder,poster,'
  + 'preload,radiogroup,readonly,rel,required,reversed,rows,rowspan,sandbox,'
  + 'scope,scoped,seamless,selected,shape,size,type,text,password,sizes,span,'
  + 'spellcheck,src,srcdoc,srclang,srcset,start,step,style,summary,tabindex,'
  + 'target,title,type,usemap,value,width,wrap'
)
function fileChange( self, fileList ){
  let dataList = []
  fileList.forEach(item => {
    //console.log(item)
    dataList.push({
      uid:item.uid,
      size:item.size,
      status: item.status,
      name:item.name,
      url:item.response?item.response.data.url:item.url
    })
  });
  checkLimit(self,fileList)
  self.$emit( 'input', dataList )
}
function checkLimit(self, fileList) {
  try {
    if(self.conf['list-type'] === 'picture-card') {
      if (fileList.length >= self.conf.limit) {
        self._self.$children[0].$children[1].$el.style.display = 'none'
      } else {
        self._self.$children[0].$children[1].$el.style.display = ''
      }
    }
  }catch{}
}
function vModel ( self, dataObject, value ) {
  if(dataObject.props.tag == 'el-date-picker') {
    dataObject.props.pickerOptions= {
      disabledDate(time) {
        if(!dataObject.props.dateRange || dataObject.props.dateRange == '') {
          return false
        }
        let dataRange = dataObject.props.dateRange
        if(dataRange == '0' || dataRange == '0-0') {
          //只能选今天
          const start = new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1);
          return time.getTime() < new Date().getTime() - 1 * 24 * 60 * 60 * 1000 || time.getTime() > new Date().getTime() + (start - new Date().getTime());
        }
        let range = dataRange.split('-',2)
        if (range.length == 0) {
          return false
        }
        let start = parseInt(range[0])
        let end = parseInt('')
        if (range.length > 1) {
          end = parseInt(range[1])
        }
        if(Number.isNaN(start) && Number.isNaN(end)) {
          return false
        }
        if(Number.isNaN(start)) {
          //m天之前
          return time.getTime() > (Date.now() + end * 24 * 60 * 60 * 1000)
        }
        if(Number.isNaN(end)) {
          //n天之后
          return time.getTime() < Date.now()- (start+1) * 24 * 60 * 60 * 1000
        }
        return  time.getTime() > (Date.now() + end * 24 * 60 * 60 * 1000) || time.getTime() < Date.now()- (start+1) * 24 * 60 * 60 * 1000
      },
    }
  }
  if(dataObject.props.tag == 'el-upload') {
    dataObject.props.fileList = value
    checkLimit(self,value)
    dataObject.props.onSuccess=(response,file, fileList)=>{
      fileChange(self,fileList)
    }
    dataObject.props.onRemove=(file, fileList)=>{
      fileChange(self,fileList)
    }
    /*dataObject.props.onChange=(file, fileList)=>{
      fileChange(self,fileList)
    }*/
    dataObject.props.onPreview=(file)=>{
      if (self.conf['list-type'] === 'picture-card') {
        //图片才预览
        self.$emit('preview', self.conf, file, dataObject.props.fileList)
      }else {
        let a = document.createElement('a');
        let event = new MouseEvent('click');
        a.download = file.name;
        a.href = file.url;
        a.dispatchEvent(event);
      }
    }
  }else {
    dataObject.props.value = value
  }
  dataObject.on.blur = (val) => {
    self.$emit( 'blur', dataObject)
  }
  dataObject.on.input = (val) => {
    self.$emit( 'input', val )
  }
  // 因为有些组件的v-model绑定的事件是change 所以这里也得监听
  dataObject.on.change = val => {
    self.$emit( 'input', val )
  }
}

const componentChild = {
  'el-input': {
    prepend ( h, conf, key ) {
      return <template slot="prepend">{conf[key]}</template>
    },
    append ( h, conf, key ) {
      return <template slot="append">{conf[key]}</template>
    },
  },
  "el-divider": {
    content(h, conf, key) {
      return conf[key];
    },
  },
  'el-select': {
    options ( h, conf, key ) {
      const list = []
      conf.options.forEach( item => {
        list.push( <el-option label={item.label} value={item.value} disabled={item.disabled}></el-option> )
      } )
      return list
    }
  },
  'el-radio-group': {
    options ( h, conf, key ) {
      const list = []
      conf.options.forEach( item => {
        if ( conf.optionType === 'button' ) list.push( <el-radio-button label={item.value}>{item.label}</el-radio-button> )
        else list.push( <el-radio label={item.value} border={conf.border}>{item.label}</el-radio> )
      } )
      return list
    }
  },
  'el-checkbox-group': {
    options ( h, conf, key ) {
      const list = []
      conf.options.forEach( item => {
        if ( conf.optionType === 'button' ) {
          list.push( <el-checkbox-button label={item.value}>{item.label}</el-checkbox-button> )
        } else {
          list.push( <el-checkbox label={item.value} border={conf.border}>{item.label}</el-checkbox> )
        }
      } )
      return list
    }
  },
  'el-upload': {
    'list-type': ( h, conf, key ) => {
      const list = []
      if ( conf['list-type'] === 'picture-card' ) {
        list.push( <i class="el-icon-plus"></i> )
      } else {
        list.push( <el-button size="small" type="primary" icon="el-icon-upload">{conf.buttonText}</el-button> )
      }
      if ( conf.showTip ) {
        list.push( <div slot="tip" class="el-upload__tip">只能上传不超过 {conf.fileSize}{conf.sizeUnit} 的{conf.accept}文件</div> )
      }
      return list
    }
  }
}
export default {
  create(){

  },
  render ( h ) {
    const confClone = JSON.parse( JSON.stringify( this.conf ) )
    const dataObject = {
      attrs: {},
      props: {
        formData: this.formData,
        value: this.value || confClone['defaultValue'],
        fileList:[]
      },
      on: {
      },
      style: {},
    }
    const children = []

    const childObjs = componentChild[confClone.tag]
    if ( childObjs ) {
      Object.keys( childObjs ).forEach( key => {
        const childFunc = childObjs[key]
        if ( confClone[key] ) {
          children.push( childFunc( h, confClone, key ) )
        }
      } )
    }

    Object.keys( confClone ).forEach( key => {
      const val = confClone[key]
      if ( key === 'vModel' ) {
        vModel( this, dataObject, this.value === undefined ? confClone['defaultValue'] : this.value )
      } else if ( dataObject[key] ) {
        dataObject[key] = val
      } else if ( !isAttr( key ) ) {
        dataObject.props[key] = val
      } else {
        dataObject.attrs[key] = val
      }
    } )
    return h( this.conf.tag, dataObject, children )
  },
  props: ['conf', 'value', 'formData']
}
