<template>
  <div class="fc-editor">
    <vue-editor
      :id="`vueeditor${renderKey}`"
      useCustomImageHandler
      :disabled="disabled"
      @image-added="handleAddImage"
      :editor-toolbar="toolbar"
      v-model="innerValue"
      :sty="{width: '100%',cursor: disabled ? 'no-drop' : '', backgroundColor: '#F5F7FA'}"></vue-editor>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { uploadFile } from "@/api/file/file";
export default {
  model: {
    prop: 'value',
  },
  props: ["value", "cmpType", "disabled","tag","tagIcon",  "renderKey", "layout"],
  name: 'fc-editor',
  components:{
    VueEditor
  },
  data () {
    return {
      toolbar:[ [{
        header: [false, 1, 2, 3, 4, 5, 6]
      }], ["bold", "italic", "underline", "strike"], // toggled buttons
        [{
          align: ""
        }, {
          align: "center"
        }, {
          align: "right"
        }, {
          align: "justify"
        }], ["blockquote", "code-block"], [{
          list: "ordered"
        }, {
          list: "bullet"
        }, {
          list: "check"
        }], [{
          indent: "-1"
        }, {
          indent: "+1"
        }], // outdent/indent
        [{
          color: []
        }, {
          background: []
        }], // dropdown with defaults from theme
        ["link", "image",,'blockquote', 'code-block'], ["clean"] // remove formatting button
      ],
      innerValue: this.value
    }
  },
  methods: {
    handleAddImage(file, Editor, cursorLocation, resetUploader){

      let formData = new FormData();
      formData.append("file", file);
      formData.append("category", "flow");
      formData.append("public", "2")
      uploadFile(formData).then(response => {
        Editor.insertEmbed(cursorLocation, "image", response.data.url);
        resetUploader();
      }).catch(e => {
        console.log(e)
      })

    }
  },
  watch:{
    innerValue (val) {
      this.$emit('input', val)
    },
    value (val) {
      if (val !== this.innerValue) {
        this.innerValue = val
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
</style>
