<script>
import Vue from "vue";
import FormControls from "../FormControls/index.js";
Vue.use(FormControls);
import render from "./utils/render.js";
import { trigger } from "./components/generator/config.js";

import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";

/**
 * 校验组织机构组件是否为空
 */
const setFcOrgSelectRule = (conf, ctx) => {
    return {
        validator: (rule, value, callback) => {
            var val = ctx.formModel[rule.field];
            if (Object.keys(val || {}).length === 0) {
                callback();
                return;
            }

            var count = 0;
            conf.tabList.forEach((key) => {
                val && Array.isArray(val[key]) && (count += val[key].length);
            });
            callback(count ? undefined : new Error(`${conf.title}不能为空`));
        },
        trigger: trigger[conf.tag],
        type: "object",
        required: true,
    };
};
/**
 * 收集表单必填项并组装成ElementUI表单校验得rules选项
 * 表格除外 表格自带校验
 */
function buildRules(conf, ctx) {
    if (conf.vModel === undefined || !trigger[conf.tag]) return;
    const rules = [];
    if (conf.required) {
        const type = Array.isArray(conf.defaultValue) ? "array" : undefined;
        let message = Array.isArray(conf.defaultValue)
            ? `请至少选择一个`
            : conf.placeholder;
        if (message === undefined) message = `${conf.label}不能为空`;
        conf.tag === "fc-org-select"
            ? rules.push(setFcOrgSelectRule(conf, ctx))
            : rules.push({
                  required: true,
                  type,
                  message,
                  trigger: trigger[conf.tag],
              });
    }
    // 自定义正则匹配
    if (conf.regList && Array.isArray(conf.regList)) {
        conf.regList.forEach((item) => {
            if (item.pattern) {
                rules.push({
                    pattern: eval(item.pattern),
                    message: item.message,
                    trigger: trigger[conf.tag],
                });
            }
        });
    }
    ctx.ruleList[conf.vModel] = rules;
}

var setData = (ctx, val, prop, init = false) => {
    if (!prop) return;
    ctx.$set(ctx.formModel, prop, val);
};

let _isMounted = false; // 收集默认值 渲染完成之后防止重复收集默认值

const buildData = (ctx, value, prop) => setData(ctx, value, prop, true);

const layouts = {
    colFormItem: function (conf, h, ctx, isList = false) {
        buildRules(conf, ctx);
        !_isMounted && buildData(ctx, conf.defaultValue, conf.vModel);
        let labelWidth = "";
        if (conf.labelWidth) labelWidth = `${conf.labelWidth}px`;
        if (isList) labelWidth = "0px";
        const required =
            (!trigger[conf.tag] && conf.required) ||
            conf.tag === "fc-org-select";
        const handleInput = (val) => {
            setData(ctx, val, conf.vModel);
            if (conf.type === "radio" || conf.type === "checkbox") {
                ctx.formDataChange(conf.formId);
            }

            if (["input", "textarea"].includes(conf.tagIcon)) {
                ctx.$emit("inputChange", conf.vModel, val);
            }
            if (
                [
                    "fc-amount",
                    "el-input-number",
                    "el-slider",
                    "fc-calculate",
                    "fc-date-duration",
                    "fc-time-duration",
                    "fc-org-select",
                    "el-select",
                    "el-radio-group",
                ].includes(conf.tag)
            ) {
                ctx.$emit("conditionChange");
                ctx.formDataChange(conf.formId);
            }
            if (conf.tag === "fc-org-select") {
                /**
                 * 组织机构组件数据复杂
                 * async-validator不一定能准确捕获到数据变化
                 * 所以在这里手动校验一次
                 */
                ctx.$refs[ctx.confGlobal.formRef].validateField(
                    conf.vModel,
                    () => {}
                );
            }
        };
        const handlePreview = (conf, file, fileList) => {
            //viewjs浏览
            ctx.imgViewerId = "viewer_" + new Date().getTime();
            ctx.imgViewerFileList = fileList;
            ctx.imgViewer && ctx.imgViewer.destroy();
            ctx.$nextTick(() => {
                ctx.imgViewer = new Viewer(
                    document.getElementById(ctx.imgViewerId)
                );
                ctx.imgViewer.view(
                    fileList.findIndex((item) => item.url === file.url)
                );
            });
        };
        const handleBlur = (val) => {
            // ctx.$emit("formDataChange", val);
            if (conf.tag !== "el-select") {
                ctx.formDataChange(conf.formId);
            }
        };

        let item = (
            <el-col span={conf.span} v-show={!conf.show}>
                <el-form-item
                    label-width={labelWidth}
                    label={isList || conf.hideLabel ? "" : conf.label}
                    prop={conf.vModel}
                >
                    <render
                        formData={ctx.formModel}
                        conf={conf}
                        value={ctx.formModel[conf.vModel]}
                        ref={conf.rowType === "table" ? conf.vModel : undefined}
                        onInput={handleInput}
                        onPreview={handlePreview}
                        onBlur={handleBlur}
                    />
                </el-form-item>
            </el-col>
        );
        if (isList) {
            var tableTitle = (
                <el-col
                    span={24}
                    style="font-size: 14px;padding:6px 0px;margin-bottom: 4px;border-bottom: 1px solid #e5e5e5;"
                    v-show={!conf.show}
                >
                    {conf.label}
                </el-col>
            );
            return [tableTitle, item];
        }
        return item;
    },

    rowFormItem: function (conf, h, ctx) {
        if (conf.rowType === "table") {
            ctx.tableRefs[conf.vModel] = conf;
            const param = {
                ...conf,
                config: conf,
                formSize: ctx.confGlobal.size,
                labelWidth: `${conf.labelWidth || ctx.confGlobal.labelWidth}px`,
            };
            return this.colFormItem(param, h, ctx, conf.type === "list");
        }
        buildRules(conf, ctx);
        const props = {
            type: conf.type === "default" ? undefined : conf.type,
            justify: conf.type === "default" ? undefined : conf.justify,
            align: conf.type === "default" ? undefined : conf.align,
            gutter: typeof conf.gutter === "number" ? conf.gutter : undefined,
        };
        let row = (
            <el-col span={conf.span || 24} v-show={!conf.show}>
                <el-row {...{ props: props }}>
                    {Array.isArray(conf.children) &&
                        conf.children.map((el) =>
                            layouts[el.layout](el, h, ctx)
                        )}
                </el-row>
            </el-col>
        );
        let divider;
        if (conf.showDivider) {
            let explain;
            if (conf.cmpType === "custom" && conf.explain) {
                explain = (
                    <el-tooltip
                        effect="dark"
                        content="组件说明"
                        placement="top"
                    >
                        <i
                            class="el-icon-info"
                            onClick={ctx.showExplain.bind(
                                this,
                                conf.explain,
                                conf.label
                            )}
                            style="margin-right: 8px; color: #E6A23C;cursor: pointer;"
                        ></i>
                    </el-tooltip>
                );
            }
            divider = (
                <el-col span={24} v-show={!conf.show}>
                    <el-divider content-position="left">
                        {explain} {conf.label}
                    </el-divider>
                </el-col>
            );
            return [divider, row];
        }
        return row;
    },
};

export default {
    props: {
        conf: Object,
        confData: Object,
        formBtns: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
    },
    data() {
        return {
            imgViewerFileList: [],
            imgViewer: null,
            imgViewerId: "viewer_" + new Date().getTime(),
            tableRefs: {},
            drawerTitle: "",
            drawerText: "",
            drawerVisible: false,
            containerWidth: 100,
            confGlobal: this.$route.params.formData || null,
            formModel: {},
            ruleList: {},
            calculationTypes: [
                {
                    value: "=",
                    label: "等于",
                },
                {
                    value: "≠",
                    label: "不等于",
                },
                {
                    value: "⊆",
                    label: "包含",
                },
                {
                    value: "⊄",
                    label: "不包含",
                },
            ],
            actionTypes: [
                { value: "1", label: "显示表单字段" },
                { value: "2", label: "隐藏表单字段" },
                { value: "3", label: "允许编辑表单字段" },
                { value: "4", label: "禁止编辑表单字段" },
                { value: "5", label: "设置表单字段值" },
            ],
        };
    },
    watch: {
        conf: {
            handler(newVal) {
                this.confGlobal = newVal;
            },
            immediate: true,
            deep: false,
        },
        confData: {
            handler(val) {},
            immediate: true,
            deep: false,
        },
    },
    mounted() {
        if (!this.confGlobal) {
            this.getConfigByAjax();
        } else {
            this.initDefaultData(this.confGlobal);
        }
        this.getFieldsInit();
        _isMounted = true;

        this.$emit("formMounted");
    },
    beforeDestroy() {
        //重要
        _isMounted = false;
    },
    methods: {
        //表单初始化
        getFieldsInit() {
            let formLinks = this.confGlobal.formLinks || [];
            if (formLinks.length > 0) {
                formLinks.forEach((formLink) => {
                    if (formLink.fieldRules.length > 0) {
                        let condition = false;
                        let arr = formLink.fieldRules.map((item) => {
                            return this.getCalculate(item, item.condition);
                        });
                        if (formLink.calculationType === "&&") {
                            condition = arr.every(
                                (value, index) => index === 0 || value
                            );
                        } else {
                            condition = arr.filter((value) => value).length > 0;
                        }
                        console.log(condition, "condition");
                        formLink.actionList.forEach((obj) => {
                            obj.field.forEach((field) => {
                                let index = this.confGlobal.fields.findIndex(
                                    (o) => field == o.formId
                                );
                                this.setConfGlobal(obj, index, condition);
                            });
                        });
                    }
                });
            }
        },
        getCalculate(item, formId) {
            let index1 = this.confGlobal.fields.findIndex(
                (o) => o.formId == formId
            );
            let index2 = this.confGlobal.fields.findIndex(
                (o) => o.formId == item.value
            );
            if (
                (item.valueType == 1 && index1 > -1) ||
                (item.valueType == 2 && index2 > -1 && index1 > -1)
            ) {
                let vModel = this.confGlobal.fields[index1].vModel;
                let vModel2 = "";
                if (item.valueType == 2) {
                    vModel2 = this.confGlobal.fields[index2].vModel;
                }
                switch (item.calculationType) {
                    case "=":
                        return item.valueType == 1
                            ? item.value == this.formModel[vModel]
                            : this.formModel[vModel2] ===
                                  this.formModel[vModel];

                    case "≠":
                        return item.valueType == 1
                            ? item.value != this.formModel[vModel]
                            : this.formModel[vModel2] !==
                                  this.formModel[vModel];
                    case "⊆":
                        return item.valueType == 1
                            ? item.value.indexOf(this.formModel[vModel]) != -1
                            : this.formModel[vModel2].indexOf(
                                  this.formModel[vModel]
                              ) != -1;
                    case "⊄":
                        return item.valueType == 1
                            ? item.value.indexOf(this.formModel[vModel]) == -1
                            : this.formModel[vModel2].indexOf(
                                  this.formModel[vModel]
                              ) == -1;
                }
            }
        },
        getAction() {},
        setConfGlobal(obj, index, value) {
            if (obj.action == "1") {
                this.confGlobal.fields[index].show = !value;
            } else if (obj.action == "2") {
                this.confGlobal.fields[index].show = value;
            } else if (obj.action == "3") {
                this.confGlobal.fields[index].disabled = !value;
            } else if (obj.action == "4") {
                this.confGlobal.fields[index].disabled = value;
            } else if (obj.action == "5") {
                this.confGlobal.fields[index].defaultValue = obj.value;
            }
        },
        formDataChange(formId) {
            let formLinks = this.confGlobal.formLinks || [];
            if (formLinks.length) {
                this.confGlobal.fields.forEach((item) => {
                    //判断是不是表单字段
                    if (item.layout === "rowFormItem") {
                        // ietm.defaultValue = this.formModel[ietm.vModel];
                        item.renderKey = item.formId + new Date().getTime(); // 改变renderKey后可以实现强制更新组件
                        if (item.children && item.children.length) {
                            item.children.forEach((child) => {
                                child.defaultValue =
                                    this.formModel[child.vModel];
                                child.renderKey =
                                    child.formId + new Date().getTime(); // 改变renderKey后可以实现强制更新组件
                            });
                        }
                    } else {
                        item.defaultValue = this.formModel[item.vModel];
                        item.renderKey = item.formId + new Date().getTime(); // 改变renderKey后可以实现强制更新组件
                    }
                });
           

            for (let link_i = 0; link_i < formLinks.length; link_i++) {
                let formLink = formLinks[link_i];
                let condition = false;
                if (formLink.fieldRules.length > 0) {
                    if (
                        formLink.fieldRules.findIndex(
                            (o) =>
                                o.condition == formId ||
                                (o.valueType == 2 && o.value == formId)
                        ) > -1
                    ) {
                        let arr = formLink.fieldRules.map((item) => {
                            return this.getCalculate(item, item.condition);
                        });

                        if (arr.length > 1) {
                            if (formLink.calculationType === "&&") {
                                condition = arr.every(
                                    (value, index) => index === 0 || value
                                );
                            } else {
                                condition =
                                    arr.filter((value) => value).length > 0;
                            }
                        } else {
                            condition = arr[arr.length - 1];
                        }
                        formLink.actionList.forEach((obj) => {
                            obj.field.forEach((field) => {
                                let index = this.confGlobal.fields.findIndex(
                                    (o) => field == o.formId
                                );
                                this.setConfGlobal(obj, index, condition);
                            });
                        });
                    }
                }
            
            }
            this.confGlobal = JSON.parse(JSON.stringify(this.confGlobal));
                this.initDefaultData(this.confGlobal);
            }
        },
        getDataFields() {
            return this.formModel;
        },
        getData() {
            return new Promise((resolve, reject) => {
                const isTableValid = this.checkTableData();

                this.$refs[this.confGlobal.formRef].validate((valid) => {
                    if (!valid) {
                        reject("valid error!");
                    } else if (!isTableValid) {
                        reject("table valid error!");
                    } else {
                        resolve(this.formModel);
                    }
                });
            });
        },
        submitForm() {
            const isTableValid = this.checkTableData();
            this.$refs[this.confGlobal.formRef].validate((valid) => {
                if (!valid) return;
                if (!isTableValid) return;
                /*this.$notify({
            title: '表单数据',
            message: '请在控制台中查看数据输出',
            position: 'bottom-right'
          });
          console.log('表单数据', this.formModel)*/
            });
        },

        resetForm() {
            this.$refs[this.confGlobal.formRef].resetFields();
            this.resetTableData();
        },

        resetTableData() {
            Object.keys(this.tableRefs).forEach((vModel) => {
                // 由于render.js返回的动态组件 所以动态组件相当于是render的子组件
                const res = this.$refs[vModel]["$children"][0].reset();
            });
        },

        checkTableData() {
            let valid = true;
            Object.keys(this.tableRefs).forEach((vModel) => {
                const res = this.$refs[vModel].$children[0].submit(); // 返回false或表单数据
                res ? (this.formModel[vModel] = res) : (valid = false);
            });
            return valid;
        },

        showExplain(explainText, title) {
            if (!explainText) return;
            this.drawerTitle = title;
            this.drawerText = explainText;
            this.drawerVisible = true;
        },

        buildDrawer(h) {
            var content = (
                <pre style="padding-left: 1rem;">{this.drawerText}</pre>
            );
            return h(
                "el-drawer",
                {
                    props: {
                        title: this.drawerTitle + "说明",
                        visible: this.drawerVisible,
                    },
                    on: {
                        "update:visible": (val) => (this.drawerVisible = val),
                    },
                },
                [content]
            );
        },
        /*buildElImgViewer(h){
        return h('el-image-viewer', {
            props: {
              urlList:this.imgPreviewUrl,
              zIndex: 3000,
              initialIndex: this.imgViewerInitialIndex,
              onClose:()=>{
                this.imgViewerVisible = false
              }
            },
            directives:[{
              name:'show',
              value:this.imgViewerVisible
            }]
          })
      },*/
        buildViewer(h) {
            const content = this.imgViewerFileList.map((item) => {
                return (
                    <li>
                        <img src={item.url} alt={item.name} />
                    </li>
                );
            });
            return h("ul", {}, [content]);
        },
        buildForm(h) {
            let labelPosition = this.confGlobal.labelPosition || "left";
            const content = this.confGlobal.fields.map((c) =>
                layouts[c.layout](c, h, this)
            );
            const formObject = {
                props: {
                    model: this.formModel,
                    rules: this.ruleList,
                    disabled: this.confGlobal.disabled,
                    size: this.confGlobal.size,
                    labelWidth: this.confGlobal.labelWidth + "px",
                    labelPosition: this.confGlobal.labelPosition || undefined,
                },
                ref: this.confGlobal.formRef,
            };
            var btns = undefined;
            if (this.formBtns) {
                btns = (
                    <el-col span={24}>
                        <el-form-item style="text-align:right;">
                            <el-button type="primary" onClick={this.submitForm}>
                                提交
                            </el-button>
                            <el-button onClick={this.resetForm}>重置</el-button>
                        </el-form-item>
                    </el-col>
                );
            }
            // 因为使用jsx时  el-form 的 model 一直无法正确填充，故采用createElement直接渲染
            return h("el-form", formObject, [content, btns]);
        },
        initDefaultData(config) {
            config.fields.forEach((field) => {
                this.formModel[field.vModel] = field.defaultValue;
            });
        },
        setData(value, field) {
            this.formModel[field] = value;
        },
        getConfigByAjax() {
            this.confGlobal = Object.freeze(this.conf);
            this.initDefaultData(this.conf);
        },
    },
    render(h) {
        if (!this.confGlobal) {
            return <div v-loading="true" class="loading-mask"></div>;
        }
        return (
            <div
                class="preview-container"
                style={"width:" + this.containerWidth + "%;"}
            >
                <el-row
                    gutter={this.confGlobal.gutter}
                    style="padding: 0 2rem 0;"
                >
                    {this.buildForm(h)}
                </el-row>
                <div class="width-slider">
                    <el-slider
                        vModel={this.containerWidth}
                        min={30}
                        max={96}
                    ></el-slider>
                </div>
                <div style="display:none;z-index:9999" id={this.imgViewerId}>
                    {this.buildViewer(h)}
                </div>
                {this.buildDrawer(h)}
            </div>
        );
    },
};
</script>
<style lang="stylus" scoped>
.preview-container {
    /* margin 3rem auto 1rem
    border-radius 6px
    box-shadow 0 0 15px rgba(0, 0, 0, .1) */
    .width-slider {
        width: 150px;
        position: absolute;
        top: 0;
        right: 2rem;
        display: none;
    }
}

.showDivider.form-container {
    margin-bottom: 2rem;
}

.loading-mask {
    width: 100vw;
    height: 100vh;
}

/deep/ .el-input.is-disabled .el-input__inner {
    text-align: left;
}
</style>
