<template>
  <div :style="{'color': textColor}" class="fc-text">
    <el-icon class="el-icon-warning-outline"></el-icon>
    <span> {{value}}</span>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'value',
  },
  props: {
    textColor:{
      type: String,
      default: '#868686'
    },
    value: {
      type: String,
      default: '这是一段描述文字'
    }
  },
  name: 'fc-text',
  data () {
    return {
      innerValue: this.value
    }
  },
  methods: {
  },
  watch:{
    value (val) {
      if (val !== this.innerValue) {
        this.innerValue = val
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
</style>
