<template>
  <div >
    <div  class="process">
      <div   class="progress">
        <template v-for="(step,sIndex) in steps">
          <div  class="progress-item" v-if="sIndex != steps.length-1">
            <div >
              <div  class="item-user">
                <div  class="item-user-avatar">
                  <div   class="link-avatar">
                    <div  class="a-img">
                      <el-avatar :fontSize="12" :size="40" icon="fa fa-users" fontSize="25" shape="square"
                                 style="background-color: #3489ff" v-if="step.nodeType == 'approver' && step.count>1 "></el-avatar>
                      <el-avatar :fontSize="12" :size="40" icon="fa fa-volume-up" fontSize="20" shape="square"
                                 style="background-color: #3489ff"v-else-if="step.nodeType == 'copy'"></el-avatar>
                      <el-avatar :fontSize="12" :size="40" :src="step.avatar" shape="square"
                                 style="background-color: #3489ff" v-else-if="step.avatar && step.avatar.indexOf('/') !== -1">{{getAvatarNickname(step.nickName)}}</el-avatar>
                      <el-avatar :fontSize="12" :size="40" shape="square" style="background-color: #3489ff"
                                 v-else>{{step.avatar}}</el-avatar>
                      <span  class="status success el-icon-success" v-if="['submit'].includes(step.code)"></span>
                      <span  class="status cc el-icon-s-promotion" v-if="['copy','notifier'].includes(step.code)"></span>
                      <span  class="status error el-icon-error" v-if="'reject'==step.code"></span>
                      <span  class="status comment el-icon-s-comment" v-if="'comment'==step.code"></span>
                      <span class="status candidate el-icon-more" v-if="'candidate'==step.code"></span>

                    </div>
                  </div>
                </div>
                <div  class="item-user-desc">
                  <div >
                    {{ step.title }}
                  </div>
                  <div >
                    {{ step.desc }} <span class="read-all" v-if="step.nodeType=='copy' && step.reads==step.count && step.count>0">全部标记已读</span>
                  </div>
                </div>
              </div>
              <div  class="time" @click="expandedCell(step)">
                {{formatDate(step)}}
                <template v-if="false">
                  <i class="el-submenu__icon-arrow el-icon-arrow-up" v-if="!step.expanded"></i>
                  <i class="el-submenu__icon-arrow el-icon-arrow-down" v-else></i>
                </template>
              </div>
            </div>
            <!--                && !step.expanded-->
            <div  class="multi-user" v-if="step.count>0 " >
              <div v-for="(it,index) in step.links">
                <div  class="item-user-avatar">
                  <div   class="link-avatar show-y">
                    <div  class="a-img">
                      <el-avatar :fontSize="12" :size="35" shape="square" :src="it.avatar" style="background-color: #3489ff"
                                 v-if="it.avatar && it.avatar.indexOf('/') !== -1">{{getAvatarNickname(it.nickName)}}</el-avatar>
                      <el-avatar :fontSize="12" :size="35" shape="square" style="background-color: #3489ff"
                                 v-else>{{getAvatarNickname(it.nickName)}}</el-avatar>
                      <span  class="read-status success el-icon-success" v-if="['notifier', 'copy'].includes(step.type)&&it.read"></span>
                    </div>
                    <div   :title="it.nickName" class="name line"
                           style="--row:2; width: 45px;">
                      {{it.nickName}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="user-comment" v-show="step.content != ''">
              <div  style="position: relative;">
                <div class="user-comment-content">
                  <div class="text-comment">
                    {{step.content}} <el-link type="primary" style="margin-left: 20px" v-if="step.type==='comment'">撤回</el-link>
                  </div>
                  <div  class="image-comment" style="display: none;"></div>
                  <div  class="file-comment"></div></div></div></div>
          </div>
          <div  class="progress-item end-process" v-else>
            <div  class="item-user-avatar node-icon">
              <!--                <span  class="el-icon-more multi-user-avatar" v-if="step.nodeType != 'end'">
                              </span>-->
              <span  class="el-icon-more multi-user-avatar" v-if="step.status == 1"></span>
              <span  class="el-icon-success multi-user-avatar" v-else-if="step.status == 2"></span>
              <span  class="el-icon-circle-close multi-user-avatar" v-else-if="step.status == 3"></span>
              <span  class="el-icon-error multi-user-avatar" v-else-if="step.status > 3"></span>
              <span  class="el-icon-question multi-user-avatar" v-else></span>
            </div>
            <div  class="item-user-desc" style="font-size: 16px; line-height: 34px;">
              {{step.nodeType != 'end'?'审批进行中':step.title}}
            </div>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "bpm-links",
  watch: {
    data: {
      handler(val) {
        if (val && Array.isArray(val) && val.length > 0) {
          this.steps = val
          this.stepKey = "bpm-links-"+new Date().getTime()
        }
      }
    }
  },
  props: {
    data: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      stepKey: "bpm-links-"+new Date().getTime(),
      steps: []
    };
  },
  methods: {
    getAvatarNickname(nickName) {
      return nickName.length>2?nickName.substr(nickName.length-2):nickName
    },
    endStepColor(step) {
      //-1挂起 0草稿 1待审批 2审批通过 3撤回 4驳回 5审批终止 6作废
      if (step.status == -1) {
        return "#f56c6c"
      } else if (step.status == 0) {
        return "#3c9cff"
      } else if (step.status == 1) {
        return "#f9ae3d"
      } else if (step.status == 2) {
        return "#5ac725"
      } else if (step.status >= 3 && step.status <= 6) {
        return "#f56c6c"
      }
      return "#606266"
    },
    isEndStep(step) {
      //-1挂起 0草稿 1待审批 2审批通过 3撤回 4驳回 5审批终止 6作废
      return step.nodeType == 'end' && step.status > 1
    },
    expandedCell(step) {
      if (step.count > 0) {
        step.expanded = !step.expanded
        this.stepKey = "bpm-links-"+new Date().getTime()
      }
    },
    formatDate(step) {
      if (step.count > 0 || step.time <= 0) {
        return ""
      }
      //YYYY-MM-DD HH:mm:ss
      /*let currentYear = moment().format('YYYY')
      let stepYear = moment(step.time * 1000).format('YYYY')
      if (currentYear == stepYear) {
        return moment(step.time * 1000).format('MM-DD HH:mm')
      }*/
      return moment(step.time * 1000).format('YYYY-MM-DD HH:mm')
    },
  }
}
</script>

<style lang="scss" scoped>

.close {
  position:absolute;
  top:0;
  right:0;
  color:#fff;
  cursor:pointer;
  border-radius:50%;
  background:#000
}
.link-avatar {
  display:flex;
  flex-direction:row;
  position:relative;
  align-items:center
}
.link-avatar .a-img {
  display:flex;
  border-radius:50%;
  flex-direction:column;
  justify-content:center;
  background:#fff;
  position:relative
}
.link-avatar .el-avatar {
  background:#fff
}
.link-avatar .name {
  text-align:center;
  color:#19191a;
  font-size:14px;
  margin-left:10px
}
.status {
  position:absolute;
  bottom:-4px;
  right:-8px;
  border-radius:50%;
  font-size:15px;
  background:#fff;
  border:2px solid #fff
}
.read-status {
  position:absolute;
  top:-4px;
  right:-5px;
  border-radius:50%;
  font-size:13px;
  background:#fff;
  border:none;
}
.error {
  color:#f56c6c
}
.leader {
  font-size:12px;
  color:#f78f5f
}
.candidate {
  color:#fff;
  background-color: #f9ae3d;
}
.pending {
  color:#fff;
  background-color: #f78f5f;
}
.success {
  color:#35b881
}
.transfer {
  color:#fff;
  background:#7a939d;
  font-size:12px;
  padding:1px
}
.comment {
  /*background-color:#1989fa;*/
  color:#1989fa;
}
.cc {
  color:#fff;
  font-size:12px;
  padding:1px;
  background:#1989fa
}
.cancel {
  color:#838383
}
.recall {
  color:#f56c6c
}
.show-y {
  justify-content: center;
  flex-direction: column!important;
}
.show-y .name {
  margin-left: 0!important;
}
.line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.process {
  background: #fff;
  padding: 20px 10px 50px 10px;
}
.progress {
  position:relative
}
.progress:after {
  content:"";
  position:absolute;
  width:4px;
  top:0;
  left:18px;
  color:#e4e4e4;
  background:#e4e4e4;
  height:100%
}
.end-process .node-icon span {
  font-size:30px
}
.end-process .node-icon .el-icon-success {
  color:#35b881!important;
  background:#fff!important
}
.end-process .node-icon .el-icon-error {
  color:#f56c6c!important;
  background:#fff!important
}
.end-process .node-icon .el-icon-circle-close {
  color:#979797!important;
  background:#fff!important
}
.end-process .node-icon .el-icon-more {
  color:#b0b0b0!important;
  background:#e4e4e4!important
}
.progress-item {
  z-index:1;
  margin-bottom:30px;
  position:relative
}
.progress-item .item-user>div {
  display:inline-block
}
.progress-item .user-comment {
  margin-left:60px;
  font-size:14px;
  position:relative;
  padding-top:5px
}
.progress-item .user-comment .user-comment-user {
  display:flex;
  align-items:center
}
.progress-item .user-comment .a-img span {
  font-size:12px
}
.progress-item .user-comment .user-comment-time {
  position:absolute;
  right:8px;
  top:7px;
  color:#8c8c8c;
  font-size:small
}
.progress-item .user-comment .user-comment-content {
  margin:5px 0;
  background:#eee;
  padding:5px 20px;
  border-radius:0 5px 5px 5px
}
.progress-item .user-comment .user-comment-content .image-comment {
  padding:5px 0
}
.progress-item .user-comment .user-comment-content .image-comment .image {
  height:60px;
  width:60px;
  padding:5px
}
.progress-item .user-comment .user-comment-content .image-comment img {
  border-radius:5px
}
.progress-item .user-comment .user-comment-content .file-comment .file-item {
  color:#1989fa;
  cursor:pointer
}
.progress-item .item-user-avatar {
  background:#fff;
  position:relative;
  padding:4px 0
}
.progress-item .item-user-avatar i {
  border-radius:50%;
  font-size:15px;
  color:#fff;
  border:2px solid #fff;
  position:absolute;
  padding:0;
  right:-10px;
  top:25px
}
.progress-item .item-user-avatar .el-icon-s-promotion {
  padding:1px;
  font-size:12px
}
.progress-item .item-user-avatar .el-icon-more {
  color:#fff;
  font-size:6px!important
}
.progress-item .item-user-avatar .multi-user-avatar {
  display:flex;
  height:40px;
  width:40px;
  background:#1989fa;
  color:#fff;
  font-size:28px;
  border-radius:4px;
  justify-content:center;
  align-items:center
}
.progress-item .item-user-desc {
  position:absolute;
  left:60px;
  top:5px;
  font-size:14px
}
.progress-item .item-user-desc div:first-child {
  color:#8c8c8c
}
.progress-item .item-user-desc div:nth-child(2) {
  font-size:15px;
  color:#000
}
.read-all {
  color:rgb(52, 137, 255);
  margin-left:20px
}
.time {
  font-size:15px;
  color:#8a8a8a;
  position:absolute;
  right:0;
  top:0
}
.multi-user {
  display:grid;
  margin-left:50px;
  grid-template-columns:repeat(7,60px)
}
.multi-user .item-user-avatar i {
  right:2px
}
</style>
