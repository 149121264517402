import Amount from './Amount'
import Text from './Text'
import Editor from './Editor'
import Dict from './Dict'
import Html from './Html'
import Sign from './Sign'
import Calculation from './Calculation'
import TimeDuration from './TimeDuration'
import DateDuration from './DateDuration'
import OrgTransfer from './OrgTransfer'
import OrgSelect from './OrgSelect'
import InputTable from './InputTable'
const components = [
  Text,
  Editor,
  Dict,
  Html,
  Sign,
  Amount,
  Calculation,
  TimeDuration,
  DateDuration,
  OrgTransfer,
  OrgSelect,
  InputTable
]

const install = function ( Vue ) {
  components.map( component => {
    Vue.component( component.name, component )
  } )
}

export default {
  install
}
