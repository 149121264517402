<template>
  <div>
    <el-card class="preview-card" shadow="never">
      <div slot="header" class="clearfix" style="border: none" v-if="stepsData && stepsData.length>0">
        <div class="title">
          <div>
            <div class="avatar show-y">
              <div class="a-img">
                <el-avatar shape="square" :fontSize="12" :size="40" fit="cover" :src="stepsData[0].avatar"
                           style="background-color: #3489ff">
                  {{ getAvatarNickname(stepsData[0].nickName) }}
                </el-avatar>
                <i class="status" style="display: none;">
                </i>
              </div>
              <div :title="stepsData[0].nickName" class="name line"
                   style="--row:1; width: 45px;">
                {{ stepsData[0].nickName }}
              </div>
            </div>
          </div>
          <div class="title-info">
            <div class="name">
					<span style="margin-right: 15px; color: rgb(0, 0, 0);">
						{{ flowName }}
					</span>
              <el-tag
                size="mini"
                v-for="dict in statusOptions"
                :type="dict.type"
                v-if="dict.key == taskData.processStatus"
              >{{dict.value}}</el-tag>
            </div>
            <div class="code">
              <span style="color: rgb(108, 108, 108);">编号：</span>
              <span class="id">{{ processNo }}</span>
            </div>
            <div class="code">
              <span style="color: rgb(108, 108, 108);">部门：</span>
              <span class="id">{{ taskData.dept_name }}</span>
            </div>
            <template v-if="taskData.processFinished === '1'">
              <img src="@/assets/bpm/icon/1.png" v-if="taskData.processStatus == 1"/>
              <img src="@/assets/bpm/icon/2.png" v-else-if="taskData.processStatus == 2"/>
              <img src="@/assets/bpm/icon/3.png" v-else-if="taskData.processStatus == 3"/>
              <img src="@/assets/bpm/icon/4.png" v-else-if="taskData.processStatus == 4"/>
              <img src="@/assets/bpm/icon/5.png" v-else-if="taskData.processStatus == 5"/>
              <img src="@/assets/bpm/icon/6.png" v-else-if="taskData.processStatus == 6"/>
            </template>

          </div>
          <div class="extend-options">
            <i class="el-icon-printer" @click="doPrint" title="打印"></i>
            <i class="fa fa-qrcode" title="二维码" @click="doQrcode"></i>
          </div>
        </div>
      </div>
      <bpm-links ref="bpmLinks" :data="stepsData"></bpm-links>
    </el-card>
    <el-dialog
      title="手机扫下面二维码"
      width="400px"
      :close-on-click-modal="false"
      :append-to-body="true"
      v-dialogDrag
      :visible.sync="qrcodeShow"
    >
      <div style="text-align: center">
      <canvas ref="qrCodeCanvas" style="width: 200px; height: 200px;"></canvas>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcode';
import BpmLinks from "./BpmLinks";
export default {
  name: "bpm-process-link",
  components:{
    BpmLinks
  },
  props: {
    flowName:{
      type: String,
      default: () => { return '' }
    },
    title:{
      type: String,
      default: () => { return '' }
    },
    processNo:{
      type: String,
      default: () => { return '' }
    },
    processId: [String, Number],
    taskData:{
      type: Object,
      default: () => { return undefined }
    },
    stepsData: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      qrcodeShow:false,
      statusOptions: [
        {key: "-1", value: '挂起', type: 'danger'},
        {key: "0", value: '草稿', type: ''},
        {key: "1", value: '待审批', type: 'warning'},
        {key: "2", value: '审批通过', type: 'success'},
        {key: "3", value: '撤回', type: 'danger'},
        {key: "4", value: '驳回', type: 'danger'},
        {key: "5", value: '终止', type: 'danger'},
        {key: "6", value: '作废', type: 'danger'},
      ],
    }
  },
  methods:{
    getAvatarNickname(nickName) {
      return nickName.length > 2 ? nickName.substr(nickName.length - 2) : nickName
    },
    doPrint(){
      this.$emit('doPrint')
    },
    doQrcode() {
      this.qrcodeShow = true
      this.$nextTick(()=>{
        QRCode.toCanvas(this.$refs.qrCodeCanvas, window.location.origin + '/#/bpm/task/view?procInstId='+this.processId+'&no='+this.processNo, {
          width: 200,
          height: 200
        })
      })
    }
  }
}
</script>

<style scoped>
.preview-card {
  border: none;
  border-left: 1px solid #e6ebf5;
  border-radius: 0;
}
.title {
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  padding: 0 10px 10px 10px;
  margin:0;
}

.title .title-info {
  height: 55px;
  display: flex;
  padding: 0 10px;
  position: relative;
  flex-direction: column;
  align-items: start;
  margin-left: 10px;
  justify-content: space-between;
}

.title .title-info img {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 99;
}

.title .title-info .code {
  font-size: 13px;
}

.title .title-info .name {
  font-size: 15px;
  color: #5e5e5e;
}

.title .extend-options {
  position: absolute;
  right: 0;
}

.title .extend-options i {
  cursor: pointer;
  padding: 0 10px;
}

.title > div {
  color: #666;
  display: inline-block;
}

.show-y {
  justify-content: center;
  flex-direction: column !important;
}

.show-y .name {
  margin-left: 0 !important;
}

.line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar .el-avatar {
  background: #fff
}

.avatar .name {
  text-align: center;
  color: #19191a;
  font-size: 14px;
  margin-left: 10px
}
</style>
