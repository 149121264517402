import request from '@/utils/request'

// 查询菜单列表
export function listMenu(query) {
  return request({
    url: '/authrule/list',
    method: 'get',
    params: query
  })
}

// 查询菜单详细
export function getMenu(menuId) {
  return request({
    url: '/authrule/get?id=' + menuId,
    method: 'get'
  })
}

// 查询菜单下拉树结构
export function treeselect() {
  return request({
    url: '/system/auth/addMenu',
    method: 'get'
  })
}

// 根据角色ID查询菜单下拉树结构
export function roleMenuTreeselect(roleId) {
  return request({
    url: '/system/menu/roleMenuTreeselect/' + roleId,
    method: 'get'
  })
}

// 新增菜单
export function addMenu(data) {
  return request({
    url: '/authrule/add',
    method: 'post',
    data: data
  })
}

// 修改菜单
export function updateMenu(data) {
  return request({
    url: '/authrule/edit',
    method: 'post',
    data: data
  })
}

// 删除菜单
export function delMenu(menuId) {
  return request({
    url: '/authrule/del',
    method: 'post',
    data:{ids:menuId}
  })
}
//收藏菜单
export function likeMenu(data) {
  return request({
    url: '/authcollect/like',
    method: 'post',
    data: data
  })
}
//取消收藏菜单
export function dislikeMenu(menuId,path) {
  return request({
    url: '/authcollect/dislike',
    method: 'post',
    data:{menu_id:menuId,path:path}
  })
}
