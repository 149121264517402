<template>
  <div class="fc-calculate">
    <el-input v-model="innerValue" readonly></el-input>
    <div class="explain-text" v-if="showChinese">大写：{{chinese}}</div>
  </div>
</template>
<script>
import { getAmountChinese, mergeNumberOfExps, validExp, toRPN, calcRPN, debounce } from '@/utils'
export default {
  model:{
    prop: 'value',
    event: 'change'
  },
  props:[
    "value",
    "tag",
    "showChinese",
    "formId",
    "formData",
    "expression",
    "precision",
    "rowIndex" // 计算公式放在表格中时， 需要获取在表格中的行位置
  ],
  name: 'fc-calculate',
  data(){
    //console.log(toRPN(mergeNumberOfExps(this.expression)))
    let expression = this.expression.filter(e=>!['Math.ceil', 'Math.floor'].includes(e))
    let math = ''
    if(this.expression.length>0){
        math = this.expression[0]
    }
    return {
      innerValue: this.value,
      math: math,
      RPN_EXP: toRPN(mergeNumberOfExps(expression))
    }
  },
  computed:{
    chinese(){
      return this.showChinese ? getAmountChinese(this.innerValue) : ''
    },
    rootFormData(){
      return this.formData || this.getFormData()
    }
  },
  methods:{
    getFormData () {
      let root = this.$parent
      while(root) {
        if ('vmFormData' in root) {
          return root.vmFormData
        }
        root = root.$parent
      }
    },
    getCmpVal(val){
      if(!val) {
        return 0
      }
      if(Array.isArray(val)) {
         //yyyy-MM-dd HH:mm     yyyy-MM-dd
        try {
          return this.timeCalculate(val)
        }catch (e) {
          return 0
        }
      }
      return Number.isNaN(val)?0:parseFloat(val)
    },
    timeCalculate(val) {
      let start = 0
      let end = 0
      let isTime = false
      if (val && Array.isArray(val) && val.length>0) {
        if(val[0].indexOf(":") != -1) {
          isTime = true
        }
        if(isTime && val[0].length <=6 && val[0].indexOf(" ") != -1) {
          [start, end] = val.slice(0, 2).map( c => new Date('2020/01/01 ' + c).getTime())
        }else {
          [start, end] = val.slice(0, 2).map( c => new Date(c).getTime())
        }
      }else {
        return 0
      }
      let duration = 0
      if(isTime) {
        //return '时长（小时）'
        duration = (( end - start ) / 3600000).toFixed(2)
      }else {
        //return '时长（天）'
        duration =  (( end - start ) / 86400000 ).toFixed(2)
      }
      return  parseFloat(duration)
    },
    /**
     * 获取指定组件的值
     */
    getFormVal (vModel) {
      try {
        if(vModel.indexOf('.') > -1) {
          let [tabelVModel, cmpVModel] = vModel.split('.')
          if (typeof this.rowIndex === 'number') {
            return this.getCmpVal(this.rootFormData[tabelVModel][this.rowIndex][cmpVModel]) || 0
          } else {
            return this.rootFormData[tabelVModel].reduce((sum, c) => {
              return (this.getCmpVal(c[cmpVModel]) || 0) + sum
            }, 0)
          }
        }
        return this.getCmpVal(this.rootFormData[vModel]) || 0
      } catch (error) {
        console.warn('计算公式出错, 可能包含无效的组件值', error)
        return 0
      }
    },
    /**
     * 计算表达式
     */
    execRPN(){
      const temp = this.RPN_EXP.map(t => typeof t === 'object' ? this.getFormVal(t.vModel) : t )
      let val = calcRPN(temp)
      if(Number.isNaN(val)) {
        this.innerValue = 0
      }else {
        if (this.math === 'Math.ceil') {
          val = Math.ceil(val)
        } else if (this.math === 'Math.floor') {
          val = Math.floor(val)
        }
        this.innerValue = this.precision?parseFloat(val.toFixed(this.precision)):parseFloat(val)
      }
      this.$emit('change', this.innerValue)
    }
  },

  watch:{
    formData:{
      handler: function(val) {
        if(!val) return
        if (!this.computeExps) { // formData更新可能比较频繁
          this.computeExps = debounce(this.execRPN, 500)
        }
        this.computeExps()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="stylus" scoped>
.explain-text {
  font-size: 12px;
  color: #909399;
  line-height: 20px;
}
</style>
