<template>
  <div class="fc-html">
    <span v-html="innerValue"></span>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'value',
  },
  props: {
    textColor:{
      type: String,
      default: '#868686'
    },
    value: {
      type: String,
      default: '这是一段描述文字'
    }
  },
  name: 'fc-html',
  data () {
    return {
      innerValue: this.value
    }
  },
  methods: {
  },
  watch:{
    value (val) {
      if (val !== this.innerValue) {
        this.innerValue = val
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
</style>
