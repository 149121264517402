<!-- 生成时间:2022-04-12 09:35:42 -->

<template>
    <div class="app-container">
        <!-- <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      v-show="showSearch"
      label-width="68px"
    >
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="queryParams.name"
          placeholder="请输入名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="表名称" prop="table_name">
        <el-input
          v-model="queryParams.table_name"
          placeholder="请输入表名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="表描述" prop="table_comment">
        <el-input
          v-model="queryParams.table_comment"
          placeholder="请输入表描述"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="备注" prop="remark" v-if="false">
        <el-input
          v-model="queryParams.remark"
          placeholder="请输入备注"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item>
        <el-button
          type="cyan"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form> -->

        <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <!-- <el-button
          type="info"
          style="margin-left: 10px"
          icon="el-icon-upload"
          size="mini"
          @click="openImportTable"
          v-hasPermi="['bpm:table:add']"
          >导入</el-button
        > -->

                <el-button
                    type="success"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd()"
                    >发起</el-button
                >

                <el-button
                    v-for="item in toolbtninfo[flowid]"
                    :key="item.name"
                    type="success"
                    icon="el-icon-info"
                    size="mini"
                    @click="handleToolBtnAction(item)"
                    >{{ item.label }}</el-button
                >

                <!-- <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['bpm:table:del']"
          >删除</el-button
        > -->
            </template>

            <!--默认操作按钮-->
            <template #defaultopr="{ row }">
                <el-button
                    v-for="item in rowbtninfo[flowid]"
                    :key="item.name"
                    size="mini"
                    type="text"
                    icon="el-icon-info"
                    @click="handleBtnAction(item, row)"
                    >{{ item.label }}
                </el-button>

                <!-- <el-button
          size="mini"
          type="text"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          >删除
        </el-button> -->
            </template>

            <!--自定义空数据模板-->
            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager>

        <!-- 添加或修改对话框 -->
        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入名称" />
                </el-form-item>
                <el-form-item label="表名称" prop="table_name">
                    <el-input
                        v-model="form.table_name"
                        placeholder="请输入表名称"
                        disabled="true"
                    />
                </el-form-item>
                <el-form-item label="表描述" prop="table_comment">
                    <el-input
                        v-model="form.table_comment"
                        placeholder="请输入表描述"
                        disabled="true"
                    />
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="form.remark" placeholder="请输入备注" />
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>

        <!--高级查询对话框-->
        <el-dialog
            title="高级查询"
            :visible.sync="advqueryopen"
            width="1300px"
            append-to-body
        >
            <el-row :gutter="20">
                <el-col :span="15" :xs="24">
                    <vxe-grid
                        border
                        resizable
                        keep-source
                        ref="xAdvqueryGrid"
                        id="id_xAdvqueryGrid"
                        height="500"
                        :columns="advqueryColumn"
                        :toolbar-config="advqueryToolbar"
                        :data="advqueryData"
                        :custom-config="{ storage: true }"
                        :edit-config="{
                            trigger: 'click',
                            mode: 'row',
                            showStatus: true,
                        }"
                        @toolbar-button-click="advqtoolbarButtonClickEvent"
                    >
                        <template #defaultadvqueryopr="{ row }"> </template>
                    </vxe-grid>
                </el-col>
                <el-col :span="9" :xs="24">
                    <vxe-grid
                        border
                        resizable
                        keep-source
                        ref="xAdvqueryGrid_tempfilter"
                        id="id_xAdvqueryGrid_tempfilter"
                        height="500"
                        :columns="advqueryColumn_tempfilter"
                        :data="advqueryData_tempfilter"
                    >
                        <template #defaultadvqueryopr_tempfilter="{ row }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click="handleadvqueryApplytpl(row)"
                                >应用
                            </el-button>

                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click="handleadvqueryDeltpl(row)"
                                >删除
                            </el-button>
                        </template>
                    </vxe-grid>
                </el-col>
            </el-row>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="advquery_save"
                    >确 定</el-button
                >
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <!--保存模版-->
        <el-dialog
            :close-on-click-modal="false"
            title="保存模版"
            :visible.sync="advquery_tempsave"
            width="800px"
            append-to-body
        >
            <el-form
                ref="advquery_tempsaveform"
                :model="advquery_tempsaveform"
                :rules="advquery_tempsaverules"
                label-width="80px"
            >
                <el-form-item label="模版名称" prop="tempname">
                    <el-input
                        v-model="advquery_tempsaveform.tempname"
                        placeholder="请输入模版名称"
                    />
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="advquery_save"
                    >确 定</el-button
                >
            </div>
        </el-dialog>

        <!--导出数据-->
        <el-dialog
            :close-on-click-modal="false"
            title="导出数据"
            :visible.sync="exportdialog"
            width="800px"
            append-to-body
        >
            <el-checkbox
                :indeterminate="exportisIndeterminate"
                v-model="exportcheckAll"
                @change="handleexportCheckAllChange"
                >全选</el-checkbox
            >
            <div style="margin: 15px 0"></div>

            <el-checkbox-group v-model="exportfields">
                <el-checkbox
                    v-for="field in tableColumn"
                    v-if="field.field"
                    :label="field.field"
                    :key="field.field"
                    >{{ field.title }}</el-checkbox
                >
            </el-checkbox-group>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="doExport">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="dialogVisible"> </el-dialog>

        <el-dialog title="查看二维码" :visible.sync="viewqrcodeinfourl">
            <img :src="viewqrcodeinfourl" />
        </el-dialog>

        <el-dialog title="发起" :visible.sync="showtask" width="1200px">
            <BmpTask ref="BmpTask" :propsflowid="flowid" startfrom="tabledata" @startsuccess="startsuccess"></BmpTask>
        </el-dialog>
    </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import {
    listTable,
    getTable,
    delTable,
    addTable,
    editTable,
    advquerydelTable,
    advquerysaveTable,
    advquerylistTable,
    exportTable,
} from "@/api/bpm/table";
import QRCode from "qrcode";
import TabledataSvr from "@/api/bpm/tabledata";
import { listMenu } from "@/api/core/system/menu";
import BmpTask from "../task/task";
export default {
    components: {
        BmpTask,
    },
    name: "bpm_formdata_list",

    data() {
        return {
            notshowfield : [
                "id",
                "rev",
                "flow_id",
                "report_id",
                "resource_id",
                "process_status",
                "starter_id",
                "starter_account",
                "starter_name",
                "dept_id",
                "vars",
                "fields",
                "ext",
            ],
            viewqrcodeinfourl: null,
            rowbtninfo: {
                114: [
                    {
                        label: "二维码",
                        name: "qrcode",
                    },
                ],
            },
            toolbtninfo: {
                114: [
                    {
                        label: "下载二维码",
                        name: "downqrcode",
                    },
                ],
            },
            showtask: false,
            flowid: null,
            businessname: "table", //请勿改动
            exportfields: [],
            exportcheckAll: false,
            exportisIndeterminate: false,
            exportdialog: false,

            //编辑器配置
            ueConfig: {
                // 初始容器高度
                initialFrameHeight: 300,
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 上传文件接口
                serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
                enableAutoSave: true,
                elementPathEnable: false,
                wordCount: false,
                UEDITOR_HOME_URL: "/ueditor/",
            },
            tableToolbar: {
                perfect: true,
                zoom: true,
                custom: true,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },

            // 列
            tableColumn: [],

            advqueryColumn: [
                {
                    field: "qfield",
                    title: "查询字段",
                    width: 180,
                    fixed: "left",
                    editRender: {
                        name: "select",
                        options: [
                            { value: "dd", label: "cxvs" },
                            { value: "zz", label: "cxvs" },
                        ],
                    },
                },
                {
                    field: "qopr",
                    title: "操作符",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "select", options: [] },
                },
                {
                    field: "qvalue",
                    title: "值",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "input" },
                },
                {
                    field: "qlogic",
                    title: "逻辑",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "select", options: [] },
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 110,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultadvqueryopr" },
                },
            ],
            advqueryColumn_tempfilter: [
                {
                    field: "tempname",
                    title: "模版名称",
                    width: 200,
                    fixed: "left",
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 180,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultadvqueryopr_tempfilter" },
                },
            ],
            advqueryData_tempfilter: [],
            advqueryopen: false,
            advquery_tempsave: false,
            advqueryData: [],
            advqueryToolbar: {
                buttons: [
                    { code: "myInsert", name: "新增" },
                    { code: "dofilter", name: "使用此组合条件查询" },
                ],
            },
            upload: {
                headers: { Authorization: getToken() },
                url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
            },
            dialogVisible: false,

            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 表格数据
            dataList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 字典

            // 查询参数
            queryParams: {
                fieldval: {},
                sortinfo: null,
                pageNum: 1,
                pageSize: 10,
            },
            // 表单参数
            form: {},
            advquery_tempsaveform: {},
            advquery_tempsaverules: {
                tempname: [
                    {
                        required: true,
                        message: "模版名称不能为空",
                        trigger: "blur",
                    },
                ],
            },
            // 表单校验
            rules: {},
        };
    },
    created() {
        this.flowid = this.$route.params.flowid;

        TabledataSvr.tableInfo(this.flowid).then((rs) => {
            var rows = JSON.parse(rs.data.rows);
            //console.log("rows", rows);
            this.settablecolum(rows);
        });

        listMenu({ auth: "/bpm/tabledata/list/" + this.flowid }).then((rs) => {
            if (rs.data.list.length > 0) {
                this.settagtitle(rs.data.list[0].title);
            }
        });
    },
    methods: {
        startsuccess(){
            this.showtask=false
        },
        handleToolBtnAction(item, row){
            switch (item.name) {
                case "downqrcode":
                    this.$confirm("是否下载二维码？", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        this.msgSuccess("此功能正在开发中...");
                    });
                    break;
            }
        },
        handleBtnAction(item, row) {
            // console.log("row",row)
            switch (item.name) {
                case "qrcode":
                    let text =
                        location.origin +
                        "/sbxx/qrcode/" +
                        row.id +
                        "/" +
                        this.flowid;
                    this.getQRcode(text);
                    break;
            }
        },
        getQRcode(text) {
            QRCode.toDataURL(
                text,
                { errorCorrectionLevel: "L", margin: 2, width: 512 },
                (err, url) => {
                    if (err) throw err;
                    this.viewqrcodeinfourl = url;
                    // this.QRImgUrl = url;
                    // console.log("url", url);
                    // window.open(url);
                }
            );
        },
        settablecolum(data) {
          

            let tableColumn = [{ type: "checkbox", width: 60, fixed: "left" }];

            for (let i in data) {
                let item = data[i];
                if (this.notshowfield.indexOf(item.name) == -1 && item.name.indexOf("static_")==-1) {
                    var isdatefield = item.name.indexOf("_time") != -1;
                    tableColumn.push({
                        field: item.name,
                        title: item.comment,
                        minWidth: 80,
                        formatter: isdatefield ? "formatDate" : "",
                    });
                }
            }

            tableColumn.push({
                field: "",
                title: "操作",
                width: 230,
                fixed: "right",
                align: "center",
                slots: { default: "defaultopr" },
            });
            this.tableColumn = tableColumn;
            this.getList();
        },
        settagtitle(title) {
            this.$route.meta.title = title;
            document.title = title;
            for (var i in this.$router.app.$store.state.tabsBar.visitedRoutes) {
                var onetag =
                    this.$router.app.$store.state.tabsBar.visitedRoutes[i];
                if (onetag.path == this.$route.path) {
                    this.$router.app.$store.state.tabsBar.visitedRoutes[
                        i
                    ].title = title;
                    return true;
                }
            }
            // this.$router.app.$store.state.tagsView.visitedViews[1].title="sdfsdfs"
        },

        //表单上传字段成功处理
        handleImportSuccess(response, file, fileList) {
            this.upload.open = false;
            this.upload.isUploading = false;
            var field = response.data.return_field;

            this.form[field] = this.form[field] || [];
            this.form[field].push(response.data.url);
            this[field + "_fileList"].push({ url: response.data.url });
        },
        handleUpload() {},
        /** 打开导入表弹窗 */
        openImportTable() {
            this.$refs.import.show();
        },
        handleExport() {
            if (this.total > 0) {
                this.exportdialog = true;
            } else {
                this.msgError("暂无数据可导出!");
            }
        },
        doExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportTable(
                        queryParams,
                        this.exportfields,
                        this.tableColumn
                    );
                })
                .then((response) => {
                    this.download(response.file);
                })
                .catch(function () {});
        },
        handleexportCheckAllChange(val) {
            this.exportfields = val
                ? this.tableColumn.map((item) => {
                      return item.field;
                  })
                : [];
            this.exportisIndeterminate = false;
        },
        //删除查询模版
        handleadvqueryDeltpl(row) {
            var ids = row.id;
            this.$confirm("是否确认删除?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    advquerydelTable(ids)
                        .then(() => {
                            const $grid = this.$refs.xAdvqueryGrid_tempfilter;
                            $grid.remove(row);
                        })
                        .then(() => {
                            this.msgSuccess("删除成功");
                        });
                })
                .then(() => {
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        //打开高级查询
        handleAdvquery() {
            advquerylistTable(this.businessname).then((response) => {
                this.advqueryData_tempfilter = response.data.list;
                this.advqueryopen = true;
            });
        },
        //保存条件
        advquery_save() {
            const $grid = this.$refs.xAdvqueryGrid;
            var alldata = $grid.getTableData();
            if (alldata.fullData.length == 0) {
                return false;
            }

            var opstr = {};
            var filteroptview = [];
            var Qlogicar = this.defaultQlogic();
            var queryParams = {};
            var fields = {},
                Qlogic = {};

            for (var i in this.tableColumn) {
                var col = this.tableColumn[i];
                if (col.field != null && col.field != "") {
                    fields[col.field] = col.title;
                }
            }

            for (var i in Qlogicar) {
                var lgc = Qlogicar[i];
                Qlogic[lgc.value] = lgc.label;
            }

            for (var i in alldata.fullData) {
                var onerow = alldata.fullData[i];
                queryParams[onerow.qfield] = onerow.qvalue;
                opstr[onerow.qfield] = onerow.qopr;
                filteroptview.push(
                    fields[onerow.qfield] +
                        " " +
                        Qlogic[onerow.qopr] +
                        "" +
                        onerow.qvalue
                );
            }

            queryParams.opstr = opstr;
            this.advquery_tempsaveform["businessname"] = this.businessname;
            this.advquery_tempsaveform["filteropt"] =
                JSON.stringify(queryParams);
            this.advquery_tempsaveform["filteroptview"] =
                filteroptview.join(";");

            this.$refs["advquery_tempsaveform"].validate((valid) => {
                if (valid) {
                    advquerysaveTable(this.advquery_tempsaveform).then(
                        (response) => {
                            if (response.code === 0) {
                                this.msgSuccess("保存成功");
                                this.advquery_tempsave = false;
                                this.handleAdvquery();
                            }
                        }
                    );
                }
            });
        },

        //应用查询模版
        handleadvqueryApplytpl(row) {
            var Filteropt = JSON.parse(row.filteropt);

            Filteropt["pageNum"] = 1;
            Filteropt["pageSize"] = 10;
            this.queryParams = Filteropt;
            this.advqueryopen = false;
            setTimeout(() => {
                this.getList();
            }, 300);
        },
        //删除临时条件
        handleadvqueryDeleted(row) {
            const $grid = this.$refs.xAdvqueryGrid;

            this.$confirm("是否确认删除?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    $grid.remove(row);
                })
                .then(() => {
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        advqtoolbarButtonClickEvent({ code }) {
            const $grid = this.$refs.xAdvqueryGrid;
            switch (code) {
                case "myInsert":
                    var row = $grid.insert({
                        qfield: "",
                        qopr: "=",
                        qlogic: "AND",
                    });

                    $grid.setActiveRow($grid.getData(0));
                    break;

                case "dosavefilter":
                    var alldata = $grid.getTableData();
                    if (alldata.fullData.length == 0) {
                        return false;
                    }
                    this.advquery_tempsave = true;
                    break;
                case "dofilter":
                    var alldata = $grid.getTableData();
                    if (alldata.fullData.length == 0) {
                        return false;
                    }

                    var opstr = {};

                    var queryParams = {
                        pageNum: 1,
                        pageSize: 10,
                        opstr: {},
                    };

                    for (var i in alldata.fullData) {
                        var onerow = alldata.fullData[i];
                        queryParams[onerow.qfield] = onerow.qvalue;
                        opstr[onerow.qfield] = onerow.qopr;
                    }

                    queryParams.opstr = opstr;
                    this.queryParams = queryParams;
                    this.advqueryopen = false;
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    break;
            }
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },

        /** 查询列表 */
        getList() {
            this.loading = true;

            TabledataSvr.tableDataList(this.queryParams, this.flowid)
                .then((response) => {
                    let datalist = JSON.parse(response.data.rows);
                    console.log("datalist", datalist);
                    this.dataList =this.formatdata(  datalist.rows);
                    this.total =datalist.total;
                    this.loading = false;
                })
                .catch((_) => {
                    this.loading = false;
                });
        },
        formatdata(list){
            list.forEach((item) => {
                for(let field in item){
                    

                    if(this.notshowfield.indexOf(field)==-1&& this.isjson(item[field])){
                       let tjs = JSON.parse(item[field]);//{"dep":[{"deptId":100,"deptName":"安全管理系统","nodeId":"dept_100"}]}
                      // console.log("tjs",tjs)
                       let tvalue=[]
                       if(typeof tjs =="object"){
                        for(let k in tjs){
                            let tav = tjs[k]
                            if(typeof tav =="object"){
                                //数组 或对象 [{"deptId":100,"deptName":"安全管理系统","nodeId":"dept_100"}]
                                for(let a in tav){
                                    let tba =tav[a]
                                    for(let bf in tba){//{"deptId":100,"deptName":"安全管理系统","nodeId":"dept_100"}
                                        if(bf=="nickName" || bf=="deptName"){
                                            tvalue.push(tba[bf])//取值 安全管理系统
                                        }

                                    }

                                }
                            }

                        }
                        item[field] = tvalue.join(",");//最终的值
                          
                       }
                    }

                   
                }
            });
            return list;
        },
        isjson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        // $comment字典翻译

        // 取消按钮
        cancel() {
            this.open = false;
            this.advqueryopen = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                name: undefined,
                table_name: undefined,
                table_comment: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.showtask = true;
            console.log("this.$refs", this.$refs);
            this.$nextTick(() => {
                this.$refs["BmpTask"].init();
            });

            //   this.reset();
            //   this.open = true;
            //   this.title = "添加";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getTable(id).then((response) => {
                this.form = response.data.data;

                this.open = true;
                this.title = "修改";
            });
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        editTable(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addTable(this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;

            this.$confirm('是否确认删除编号为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delTable(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
    }, //methods结束
};
</script>
