import request from '@/utils/request'
import { downloadbyurl } from "@/utils/ruoyi";
// 发起流程
export function startProcess(data) {
  return request({
    url: '/bpm/process/start',
    method: 'post',
    data: data
  })
}
// 我的流程
export function myProcessList(query) {
  return request({
    url: '/bpm/process/my/list',
    method: 'get',
    params: query
  })
}
// 待办流程
export function todoProcessList(query) {
  return request({
    url: '/bpm/process/todo/list',
    method: 'get',
    params: query
  })
}
// 已办流程
export function doneProcessList(query) {
  return request({
    url: '/bpm/process/done/list',
    method: 'get',
    params: query
  })
}

// 抄送给我的流程
export function copyProcessList(query) {
  return request({
    url: '/bpm/process/copy/list',
    method: 'get',
    params: query
  })
}

// 流程监控
export function runProcessList(query) {
  return request({
    url: '/bpm/process/run/list',
    method: 'get',
    params: query
  })
}
// 已完成的流程
export function historyProcessList(query) {
  return request({
    url: '/bpm/process/history/list',
    method: 'get',
    params: query
  })
}
// 流程实例
export function ProcessList(query) {
  return request({
    url: '/bpm/process/list',
    method: 'get',
    params: query
  })
}
//流程实例统计
export function StatProcess(query) {
  return request({
    url: '/bpm/process/stat',
    method: 'get',
    params: query
  })
}
export function ProcessMetas(data) {
  return request({
    url: '/bpm/process/meta',
    method: 'post',
    data: data
  })
}
//导出流程实例统计
export function ExportProcess(query) {
  return request({
    url: '/bpm/process/export',
    method: 'post',
    responseType: "blob" ,  //必写
    data: query
  })
}
export function PrintProcess(id, no) {
  downloadbyurl('/bpm/process/print?procInstId=' + id + '&no=' + no)
}

// 删除delProcess
export function delProcess(ids,reason) {
  return request({
    url: '/bpm/process/del',
    method: 'post',
    data:{ids:ids,reason:reason}
  })
}
//设为已读
export function readProcess(type,ids="") {
  return request({
    url: '/bpm/process/read',
    method: 'post',
    data:{ids:ids,type:type}
  })
}
