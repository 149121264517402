<template>
  <div style="border-left: 1px solid #e6ebf5;">
    <el-timeline >
      <el-timeline-item class="task"
                        type="info"
                        size="large"
                        icon="el-icon-user-solid"
      >
        <div class="step-node">
          <div>
            <div style="font-size: 16px;">发起人</div>
            <span style="color: #999;"></span>
          </div>
          <div style="display: flex">
            <div class="step-avatar show-y" ><div class="a-img">
              <el-avatar :fontSize="25" :size="38" shape="square" style="background-color: #3489ff"
                         :src="$store.state.user.avatar">{{getAvatarNickname($store.state.user.name)}}</el-avatar>
            </div>
              <div :title="$store.state.user.name" class="name line" style="--row:1; width: 48px;">
                {{ $store.state.user.name}}</div></div>

          </div>
        </div>
      </el-timeline-item>
      <el-timeline-item class="task"
                        v-for="(step,sIndex) in steps"
                        :icon="getStepIcon(step,sIndex)"
                        type="info"
                        size="large"
      >
        <div class="step-node">
          <div>
            <div style="font-size: 16px;">{{ step.title }}</div>
            <span style="color: #999;">{{ getStepDesc(step) }}</span></div>
          <div style="display: flex;">

            <template v-if="step.links.length > getShowCount(step)">
              <div class="step-avatar show-y" title="查看全部" @click="openPopup(sIndex)"><div class="a-img">
                <el-avatar :fontSize="25" :size="38" shape="square" style="background-color: #3489ff"
                           icon="fa fa-users"></el-avatar>
               </div>
                <div title="查看全部" class="name line" style="--row:1; width: 48px;">全部</div></div>
            </template>

            <template v-for="(it,index) in step.links">
              <template v-if="IsShowItem(step,index)">
                <div class="step-avatar show-y">
                  <div class="a-img">
                    <el-avatar :fontSize="25" :size="38" shape="square" :src="it.avatar" style="background-color: #3489ff"
                               v-if="it.avatar && it.avatar.indexOf('/') !== -1 && it.avatar!=''">{{getAvatarNickname(it.nickName)}}</el-avatar>
                    <el-avatar :fontSize="25" :size="38" shape="square" style="background-color: #3489ff"
                               v-else>{{getAvatarNickname(it.nickName)}}</el-avatar>
                    <i class="close el-icon-close" @click="closeHandler(sIndex,index)"
                       v-if="index>(step.count-1)"></i>
                  </div>
                  <div :title="it.nickName" class="name line" style="--row:1; width: 48px;"> {{ it.nickName }} </div>
                </div>
              </template>
            </template>
            <div class="add-user" v-if="IsOptional(step)">
              <i class="el-icon-plus" @click="openPicker(sIndex)"></i>
              <div>添加</div>
            </div>
          </div>
        </div>
      </el-timeline-item>

      <el-timeline-item class="task"
                        type="info"
                        size="large"
                        icon="el-icon-check"
      >
        <div class="step-node">
          <div>
            <div style="font-size: 16px;">结束</div>
            <span style="color: #999;"></span>
          </div>
          <div style="display: flex"></div>
        </div>
      </el-timeline-item>

    </el-timeline>
    <admin-select ref="userSelect" @doSubmit="selectUsersToInput" :limit="100" ignore-datascope="'1'" :selectData="selectData"></admin-select>
    <el-dialog   :title="`${steps[current].title}（${getStepDesc(steps[current])}）`"
                 width="800px"
                 height="500px"
                 :close-on-click-modal="false"
                 :append-to-body="true"
                 v-dialogDrag
                 :visible.sync="isPopupShow">
      <div style="height:60vh">
        <div style="display: flex;">
          <template v-for="(it,index) in steps[current].links">
            <div class="step-avatar show-y">
              <div class="a-img">
                <el-avatar :fontSize="25" :size="38" shape="square" :src="it.avatar" style="background-color: #3489ff"
                           v-if="it.avatar && it.avatar.indexOf('/') !== -1 && it.avatar!=''">{{getAvatarNickname(it.nickName)}}</el-avatar>
                <el-avatar :fontSize="25" :size="38" shape="square" style="background-color: #3489ff"
                           v-else>{{getAvatarNickname(it.nickName)}}</el-avatar>
                <i class="close el-icon-close" @click="closeHandler(current,index)"
                   v-if="index>(steps[current].count-1)"></i>
              </div>
              <div :title="it.nickName" class="name line" style="--row:1; width: 48px;"> {{ it.nickName }} </div>
            </div>
          </template>
          <div class="add-user" v-if="IsOptional(steps[current])">
            <i class="el-icon-plus" @click="openPicker(current)"></i>
            <div>添加</div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import AdminSelect from '@/components/AdminSelect/AdminSelect'

export default {
  name: "bpm-steps",
  components: {
    AdminSelect,
  },
  watch: {
    data: {
      handler(val) {
        if (val && Array.isArray(val) && val.length > 0) {
          this.steps = val
          this.stepKey = "bpm-steps-" + new Date().getTime()
        }
      }
    }
  },
  props: {
    data: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      stepKey: "bpm-steps-" + new Date().getTime(),
      isPopupShow: false,
      current: 0,
      selectData: [],
      maxCount:1,
      pickerStep:0,
      steps: [{
        nodeId: "candidates",
        title: "审批人",
        nodeType: "approver",
        content: "",
        isOptional: false,
        isMultiUser: true,
        count: 0,
        links: []
      },
        {
          nodeId: "ccs",
          title: "抄送人",
          nodeType: "copy",
          content: "",
          isOptional: true,
          isMultiUser: true,
          count: 0,
          links: []
        }
      ]
    };
  },
  methods: {
    selectUsersToInput (users) {
      let step = this.pickerStep
      let stepCount = this.steps[step].count
      let links = this.steps[step].links.filter((vItem, vIndex) => {
        return vIndex <= (stepCount - 1)
      })
      users.forEach(item => {
        links.push({
          deptId: item.dept_id,
          deptName: item.dept ? item.dept.name : '',
          avatar: item.avatar,
          nickName: item.nickname,
          nodeId: item.id,
          userId: item.id,
          userName: item.username
        })
      })
      this.steps[step].links = links
      this.stepKey = "bpm-steps-" + new Date().getTime()
      this.$forceUpdate()
    },
    getAvatarNickname(nickName) {
      if(!nickName) {
        return ''
      }
      return nickName.length > 2 ? nickName.substr(nickName.length - 2) : nickName
    },
    getStepIcon(step, index) {
      if (step.nodeType == 'approver') {
        return 'el-icon-s-check'
      }
      return 'el-icon-s-promotion'
    },
    getStepDesc(stepItem) {
      let linkCount = stepItem.links.length
      if (linkCount <= 0) {
        return stepItem.nodeType == "approver" ? `请选择审批人` : `请选择抄送人`
      }
      return stepItem.nodeType == "approver" ? `${linkCount}人审批` : `抄送${linkCount}人`
    },
    getShowCount(stepItem) {
      return 5
    },
    IsShowItem(stepItem, index) {
      let step = stepItem
      let showCount = this.getShowCount(step)
      if (index >= showCount) {
        return false
      }
      return true
    },
    IsOptional(stepItem) {
      if (stepItem.links.length <= 0) {
        return true
      }
      if (stepItem.nodeType == "approver") {
        return false
      }
      return stepItem.isOptional
    },
    openPopup(stepIndex) {
      this.current = stepIndex
      this.isPopupShow = true
    },
    // 点击关闭按钮
    closeHandler(step, linkIndex) {
      this.steps[step].links = this.steps[step].links.filter((vItem, vIndex) => {
        return vIndex != linkIndex
      })
    },
    getStepUser() {
      let users = {}
      for (let i = 0; i < this.steps.length; i++) {
        let step = this.steps[i]
        if (step.nodeType === 'approver' && step.links.length === 0) {
          this.msgError(step.title + "节点不能为空")
          return null
        }
        let userIds = []
        step.links.forEach((link, vindex) => {
          userIds.push(link.userId)
        })
        users[step.nodeId] = userIds.join(',')
      }
      return users
    },
    openPicker(step) {
      let stepCount = this.steps[step].count
      /*let links = this.steps[step].links.filter((vItem, vIndex) => {
        return vIndex > (stepCount - 1)
      })*/
      this.maxCount = this.steps[step].isMultiUser ? 100 : 1
      if (this.steps[step].nodeType == "copy") {
        this.maxCount = 100
      }
      this.pickerStep = step
      this.$refs.userSelect.init()

    },
  }
}
</script>

<style lang="scss" scoped>
.task {
  height: 60px;
}
.close {
  position:absolute;
  top:-5px;
  right:-5px;
  color:#fff;
  cursor:pointer;
  border-radius:50%;
  background:#000;
}
.step-avatar {
  display:flex;
  flex-direction:row;
  position:relative;
  align-items:center
}
.step-avatar .a-img {
  display:flex;
  border-radius:4px;
  flex-direction:column;
  justify-content:center;
  background:#fff;
  position:relative;
}
.step-avatar .el-avatar {
  background:#fff
}
.step-avatar .name {
  text-align:center;
  color:#19191a;
  font-size:14px;
  margin-left:10px
}
::deep .el-timeline {
  padding-left:30px;
}
.show-y {
  justify-content: center;
  flex-direction: column!important;
}
.show-y .name {
  margin-left: 0!important;
}
.line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 5px;
}

.add-user i {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px dashed #909399;
  cursor: pointer
}

.add-user i:hover {
  color: #1682e6;
  border: 1px dashed #1682e6
}

.step-node > div {
  position: absolute;
  display: inline-block
}

.step-node > div:last-child {
  right: 0;
  top: -10px
}

</style>
